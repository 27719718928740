// CSS
import "./Search.css"

// Módulos
import React, {Component} from "react"

class Search extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div id="search-area" className="px-3 px-md-5 mb-5">
                <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">Buscar Curso/Atividade</h6>
                <form className="container-fluid" action="" method="post">
                    <div className="row px-0">
                        <div className="col-10 col-md-11 px-0">
                            <input type="search" name="search" placeholder="O que você procura?"
                                   className="form-control search px-3 py-4"/>
                        </div>
                        <div className="col-2 col-md-1 p-0">
                            <button type="submit" className="btn w-100 h-100 text-white bg-primary submit">
                                <i className="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default Search