// CSS
import './App.css'

// Módulos
import React, {Component} from "react"
import {BrowserRouter as Router, Switch, Route} from "react-router-dom"
import ReactLoading from "react-loading"

// Components
import ScrollToTop from '../components/ScrollToTop'
import ProtectedRoute from '../components/ProtectedRoute'

// Views
import LoginView from './Login'
import DashboardView from './DashboardView'

class App extends Component {
    constructor(props) {
        super(props)

        this.state = {
            done: false,
            userData: null
        }
    }

    componentDidMount() {
        this.setState({
            done: true
        })
    }

    render() {
        return (
            <Router>
                <ScrollToTop/>

                {!this.state.done ? (
                    <div className="d-flex align-items-center justify-content-center h-100">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                                 className={"mb-2"}
                                 style={{
                                     maxHeight: "50px"
                                 }}/>
                            <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                        </div>
                    </div>
                ) : (
                    <Switch>
                        <Route path="/login" component={LoginView}/>

                        <div className="mainContainer h-100">
                            <ProtectedRoute path="/" component={DashboardView}/>
                        </div>
                    </Switch>
                )}
            </Router>
        )
    }
}

export default App
