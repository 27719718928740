import React, {Component} from "react"
import Axios from "axios"
import DataTable from 'react-data-table-component'
import SweetAlert from 'sweetalert'
import ReactLoading from "react-loading"
import {Link} from "react-router-dom";

class MeetingsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dadosMeetings: [],
            done: false,
            TableColumns: [
                {
                    name: "Data e Hora",
                    sortable: true,
                    width: "20%",
                    cell: (row) => {
                        let dateTime = new Date(row.start_time)
                        let dateTimeFormatted =
                            (dateTime.getDate() <= 9 ? "0" + dateTime.getDate() : dateTime.getDate()) + "/" +
                            ((dateTime.getMonth() + 1) <= 9 ? "0" + (dateTime.getMonth() + 1) : (dateTime.getMonth() + 1)) + "/" +
                            dateTime.getFullYear() + " " +
                            (dateTime.getHours() <= 9 ? "0" + dateTime.getHours() : dateTime.getHours()) + ":" +
                            (dateTime.getMinutes() <= 9 ? "0" + dateTime.getMinutes() : dateTime.getMinutes())
                        return (
                            <div>{dateTimeFormatted}</div>
                        )
                    }
                },
                {
                    name: "Recorrência",
                    sortable: true,
                    width: "15%",
                    cell: (row) => {
                        return row.type == 1 ? (
                            <div>Diária</div>
                        ) : (
                            row.type == 2 ? (
                                <div>Semanal</div>
                            ) : (
                                <div>Mensal</div>
                            )
                        )
                    }
                },
                {
                    name: "Assunto",
                    selector: "topic",
                    sortable: true,
                    width: "30%"
                },
                {
                    name: "Ações",
                    sortable: true,
                    width: "35%",
                    cell: (row) => {
                        return row.status == "waiting" ? (
                            <div>
                                <a href={row.start_url} className="btn btn-primary btn-sm text-white" target={"_blank"}>
                                    Iniciar agora
                                </a>
                                <a href={row.join_url} className="btn btn-secondary btn-sm ml-2" target={"_blank"}>
                                    Link
                                </a>
                                <span onClick={() => this.cancelarMeeting(row.id)} className="btn btn-danger btn-sm ml-2">
                                    Cancelar
                                </span>
                            </div>
                        ) : (
                            row.status == "cancelled" ? (
                                <div className={"badge badge-warning py-2 px-3"}>
                                    Vídeo conferência cancelada
                                </div>
                            ) : (
                                <div className={"badge badge-info py-2 px-3"}>
                                    Finalizada
                                </div>
                            )
                        )
                    }
                }
            ]
        }
    }

    ativarMeeting = (MeetingID) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza de que quer ativar esta meeting?',
            icon: 'info',
            buttons: ['Cancelar', 'Sim, quero ativá-la']
        })
            .then((response) => {
                if (response) {
                    Axios.patch(
                        'https://servicecall.octaead.com.br/meeting/' + MeetingID,
                        {status: 1}
                    )
                        .then(() => {
                            SweetAlert(
                                'Pronto!',
                                'Meeting ativada.',
                                'success'
                            ).then(() => {
                                window.location.reload()
                            })
                        })
                }
            })
    }

    desativarMeeting = (MeetingID) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza de que quer desativar esta meeting?',
            icon: 'info',
            buttons: ['Cancelar', 'Sim, quero desativá-la']
        })
            .then((response) => {
                if (response) {
                    Axios.patch(
                        'https://servicecall.octaead.com.br/meeting/' + MeetingID,
                        {status: 0}
                    )
                        .then(() => {
                            SweetAlert(
                                'Pronto!',
                                'Meeting desativada.',
                                'success'
                            ).then(() => {
                                window.location.reload()
                            })
                        })
                }
            })
    }

    cancelarMeeting = (MeetingID) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza de que quer cancelar esta reunião?',
            icon: 'warning',
            dangerMode: true,
            buttons: ['Cancelar', 'Sim, quero cancelá-la']
        })
            .then((response) => {
                if (response) {
                    Axios.post('https://servicecall.octaead.com.br/cancel-meeting/' + MeetingID)
                        .then(() => {
                            SweetAlert(
                                'Pronto!',
                                'Reunião cancelada.',
                                'success'
                            ).then(() => {
                                window.location.reload()
                            })
                        })
                }
            })
    }

    loadMeetings = async () => {
        this.setState({
            done: false
        })

        let meetingsInfo = await Axios.get('https://servicecall.octaead.com.br/calls')

        this.setState({
            dadosMeetings: meetingsInfo.data,
            done: true
        })
    }

    async componentDidMount() {
        await this.loadMeetings()
    }

    render() {
        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <div id="meetings-listagem" className="px-3 px-md-5 my-5">
                <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">Meetings</h6>
                <div className="card">
                    <div className="card-body p-4">
                        <DataTable
                            columns={this.state.TableColumns}
                            data={this.state.dadosMeetings}
                            noDataComponent={"Nenhum registro encontrado"}
                            pagination
                            striped
                            defaultSortField="username"
                        />
                        <hr className="mb-4 mt-5"/>
                        <div className="d-block text-right">
                            <Link to={"/admin/meetings/nova"}
                                  className="btn border-radius py-2 text-center bg-primary text-white"
                            >
                                Criar nova conferência
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MeetingsView;