// CSS
import "./Banner.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// Módulos
import React, {Component} from "react"
import Slider from "react-slick"

class Banner extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const settings = {
            dots: false,
            arrows: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        }

        return (
            <div className="slider px-3 px-md-5 mt-4 mb-5" id={"slider"}>
                <Slider {...settings}>
                    <div
                        className="slide py-md-5 bg-secondary text-white d-flex align-items-center justify-content-center">
                        <span>
                            <h1 className="font-weight-bold">Banner</h1>
                            <h2>1500x300</h2>
                        </span>
                    </div>
                    <div
                        className="slide py-md-5 bg-secondary text-white d-flex align-items-center justify-content-center">
                        <span>
                            <h1 className="font-weight-bold">Banner</h1>
                            <h2>1500x300</h2>
                        </span>
                    </div>
                </Slider>
            </div>
        )
    }
}

export default Banner