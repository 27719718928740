// CSS
import "./AlunoCalendarioView.css"

// Módulos
import React, {Component} from "react"
import Calendar from "react-calendar"
import SweetAlert from "sweetalert"

// Components
import ReactLoading from "react-loading";

class AlunoCalendarioView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            done: false
        }
    }

    componentDidMount() {
        this.setState({
            done: true
        })
    }

    handleCalendarChange = (event) => {
    }

    render() {
        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <div className={"mt-4"}>
                <div className="container-fluid mb-5 pb-md-4">
                    <Calendar
                        tileClassName={"btn btn-light border"}
                        className={"card p-3"}
                        onChange={this.handleCalendarChange}
                    />
                </div>
            </div>
        )
    }
}

export default AlunoCalendarioView