// Módulos
import React, {Component} from "react"

// Components
import Banner from "../../components/Banner";
import Search from "../../components/Search";
import UltimasAtividades from "../../components/UltimasAtividades";
import Atalhos from "../../components/Atalhos";
import ReactLoading from "react-loading";
import Axios from "axios";

class CoordenadorHomeView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            done: false
        }
    }

    componentDidMount() {
        this.setState({
            done: true
        })
    }

    render() {
        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <div className={"mt-4"}>
                <Banner/>
                <Search/>

                <div className="container-fluid mb-5 pb-md-4">
                    <div className="row">
                    </div>
                </div>
            </div>
        )
    }
}

export default CoordenadorHomeView