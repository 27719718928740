// CSS
import "./Sidebar.css"

// Módulos
import React, {Component} from "react"
import {Link} from "react-router-dom"
import {Accordion} from "react-bootstrap"
import Axios from "axios"
import ReactLoading from "react-loading"

class Sidebar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            role: props.role,
            alunoCursos: []
        }
    }

    async componentDidMount() {
        if (this.state.role === 'aluno') {
            let cursos = await Axios.get('https://serviceturma.octaead.com.br/cursos-aluno/' + localStorage.getItem('userId'))
            let currentSubPath = this.props.path.split('/')[2] ? this.props.path.split('/')[2] : ''
            let cursosToMap = cursos.data[0]

            await cursosToMap.map(async (item, i) => {
                let cursosInfo = this.state.alunoCursos
                Axios.get('https://servicecurso.octaead.com.br/curso/' + item)
                    .then((info) => {
                        cursosInfo.push(info.data)

                        this.setState({
                            alunoCursos: cursosInfo
                        })
                    })
            })
        }
    }

    render() {
        // Sidebar Administrador
        if (this.state.role === 'administrador') {
            let currentPath = this.props.path.split('/')[2] ? this.props.path.split('/')[2] : ''

            return (
                <aside id="sidebar"
                       className={"col-9 px-0 col-md-2 position-fixed h-100 text-white d-flex flex-column"}>

                    <div
                        className={"w-100 bg-light text-white title text-center d-flex align-items-center justify-content-center"}>
                        <Link
                            to={"/admin"}
                        >
                            <img src={process.env.PUBLIC_URL + '/images/logo192.png'} className={"img-fluid py-3"}
                                 style={{
                                     maxHeight: "70px"
                                 }}/>
                        </Link>
                    </div>

                    <nav className={"p-0 bg-primary h-100"} id="menu">
                        <Accordion defaultActiveKey={0}>
                            <ul className={"mx-0 px-0"}>
                                <li>
                                    <Link
                                        to={"/admin"}
                                        title=""
                                        className={"btn btn-primary w-100 text-white d-flex align-items-center justify-content-start py-2 rounded-0" + (currentPath == '' ? ' active' : '')}>
                                        <i className={"fas fa-home mr-3"}></i> Home
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={"/admin/planejamento"}
                                        className={"btn btn-primary w-100 text-white d-flex align-items-center justify-content-start py-2 rounded-0" + (currentPath == 'planejamento' ? ' active' : '')}
                                        eventKey={1}>
                                        <i className={"far fa-calendar-alt mr-3"}></i> Planejamento
                                    </Link>
                                </li>
                                <li>
                                    <Accordion.Toggle
                                        className={"btn btn-primary w-100 text-white d-flex align-items-center justify-content-start py-2 rounded-0" + (currentPath == 'admin' ? ' active' : '')}
                                        eventKey={1}>
                                        <i className={"fas fa-user mr-3"}></i> Usuários
                                        <i className={"fas fa-angle-down small ml-auto"}></i>
                                    </Accordion.Toggle>

                                    <Accordion.Collapse eventKey={1}>
                                        <ul className={"py-2 px-0 ml-2"}>
                                            <li>
                                                <Link to={"/admin/administradores"}
                                                      className={"btn btn-primary w-100 text-white text-left rounded-0" + (currentPath == 'administradores' ? ' active' : '')}>
                                                    <i className={"fa fa-angle-right mr-2"}></i>
                                                    Administradores
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/admin/coordenadores"}
                                                      className={"btn btn-primary w-100 text-white text-left rounded-0" + (currentPath == 'coordenadores' ? ' active' : '')}>
                                                    <i className={"fa fa-angle-right mr-2"}></i>
                                                    Coordenadores
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/admin/professores"}
                                                      className={"btn btn-primary w-100 text-white text-left rounded-0" + (currentPath == 'professores' ? ' active' : '')}>
                                                    <i className={"fa fa-angle-right mr-2"}></i>
                                                    Professores
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/admin/alunos"}
                                                      className={"btn btn-primary w-100 text-white text-left rounded-0" + (currentPath == 'alunos' ? ' active' : '')}>
                                                    <i className={"fa fa-angle-right mr-2"}></i>
                                                    Alunos
                                                </Link>
                                            </li>
                                        </ul>
                                    </Accordion.Collapse>
                                </li>
                                <li>
                                    <Link
                                        to={"/admin/unidades"}
                                        className={"btn btn-primary w-100 text-white d-flex align-items-center justify-content-start py-2 rounded-0" + (currentPath == 'unidades' ? ' active' : '')}
                                        eventKey={1}>
                                        <i className={"fa fa-building mr-3"}></i> Unidades
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={"/admin/turmas"}
                                        className={"btn btn-primary w-100 text-white d-flex align-items-center justify-content-start py-2 rounded-0" + (currentPath == 'turmas' ? ' active' : '')}
                                        eventKey={1}>
                                        <i className={"fa fa-users mr-3"}></i> Turmas
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={"/admin/cursos"}
                                        className={"btn btn-primary w-100 text-white d-flex align-items-center justify-content-start py-2 rounded-0" + (currentPath == 'cursos' ? ' active' : '')}
                                        eventKey={1}>
                                        <i className={"fa fa-graduation-cap mr-3"}></i> Cursos
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/admin/atividades"}
                                          className={"btn btn-primary w-100 text-white d-flex align-items-center justify-content-start py-2 rounded-0" + (currentPath == 'atividades' ? ' active' : '')}
                                          eventKey={1}>
                                        <i className={"fa fa-book-open mr-3"}></i> Atividades
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/admin/meetings"}
                                          className={"btn btn-primary w-100 text-white d-flex align-items-center justify-content-start py-2 rounded-0" + (currentPath == 'meetings' ? ' active' : '')}
                                          eventKey={1}>
                                        <i className={"fa fa-video mr-3"}></i> Conferências
                                    </Link>
                                </li>
                            </ul>
                        </Accordion>
                    </nav>

                </aside>
            )
        }

        // Sidebar Coordenador
        if (this.state.role === 'coordenador') {
            let currentPath = this.props.path.split('/')[2] ? this.props.path.split('/')[2] : ''
            let currentSubPath = this.props.path.split('/')[3] ? this.props.path.split('/')[3] : ''

            return (
                <aside id="sidebar"
                       className={"col-9 px-0 col-md-2 position-fixed h-100 text-white d-flex flex-column"}>

                    <div
                        className={"w-100 bg-light text-white title text-center d-flex align-items-center justify-content-center"}>
                        <Link
                            to={"/admin"}
                        >
                            <img src={process.env.PUBLIC_URL + '/images/logo192.png'} className={"img-fluid py-3"}
                                 style={{
                                     maxHeight: "70px"
                                 }}/>
                        </Link>
                    </div>

                    <nav className={"p-0 bg-primary h-100"} id="menu">
                        <Accordion defaultActiveKey={0}>
                            <ul className={"mx-0 px-0"}>
                                <li>
                                    <Link
                                        to={"/admin"}
                                        title=""
                                        className={"btn btn-primary w-100 text-white d-flex align-items-center justify-content-start py-2 rounded-0" + (currentPath == '' ? ' active' : '')}>
                                        <i className={"fas fa-home mr-3"}></i> Home
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={"/admin/planejamento"}
                                        className={"btn btn-primary w-100 text-white d-flex align-items-center justify-content-start py-2 rounded-0" + (currentPath == 'minhas-atividades' ? ' active' : '')}
                                        eventKey={1}>
                                        <i className={"far fa-calendar-alt mr-3"}></i> Planejamento
                                    </Link>
                                </li>
                                <li>
                                    <Accordion.Toggle
                                        className={"btn btn-primary w-100 text-white d-flex align-items-center justify-content-start py-2 rounded-0" + (currentPath == 'admin' ? ' active' : '')}
                                        eventKey={1}>
                                        <i className={"fas fa-calendar-alt mr-3"}></i> Usuários
                                        <i className={"fas fa-angle-down small ml-auto"}></i>
                                    </Accordion.Toggle>

                                    <Accordion.Collapse eventKey={1}>
                                        <ul className={"py-2 px-0 ml-2"}>
                                            <li>
                                                <Link to={"/admin/coordenadores"}
                                                      className={"btn btn-primary w-100 text-white text-left rounded-0" + (currentPath == 'coordenadores' ? ' active' : '')}>
                                                    <i className={"fa fa-angle-right mr-2"}></i>
                                                    Coordenadores
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/admin/professores"}
                                                      className={"btn btn-primary w-100 text-white text-left rounded-0" + (currentPath == 'professores' ? ' active' : '')}>
                                                    <i className={"fa fa-angle-right mr-2"}></i>
                                                    Professores
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/admin/alunos"}
                                                      className={"btn btn-primary w-100 text-white text-left rounded-0" + (currentPath == 'alunos' ? ' active' : '')}>
                                                    <i className={"fa fa-angle-right mr-2"}></i>
                                                    Alunos
                                                </Link>
                                            </li>
                                        </ul>
                                    </Accordion.Collapse>
                                </li>
                            </ul>
                        </Accordion>
                    </nav>

                </aside>
            )
        }

        // Sidebar Professor
        if (this.state.role === 'professor') {
            let currentPath = this.props.path.split('/')[2] ? this.props.path.split('/')[2] : ''
            let currentSubPath = this.props.path.split('/')[3] ? this.props.path.split('/')[3] : ''

            return (
                <aside id="sidebar"
                       className={"col-9 px-0 col-md-2 position-fixed h-100 text-white d-flex flex-column"}>

                    <div
                        className={"w-100 bg-light text-white title text-center d-flex align-items-center justify-content-center"}>
                        <Link
                            to={"/admin"}
                        >
                            <img src={process.env.PUBLIC_URL + '/images/logo192.png'} className={"img-fluid py-3"}
                                 style={{
                                     maxHeight: "70px"
                                 }}/>
                        </Link>
                    </div>

                    <nav className={"p-0 bg-primary h-100"} id="menu">
                        <Accordion defaultActiveKey={0}>
                            <ul className={"mx-0 px-0"}>
                                <li>
                                    <Link
                                        to={"/admin"}
                                        title=""
                                        className={"btn btn-primary w-100 text-white d-flex align-items-center justify-content-start py-2 rounded-0" + (currentPath == '' ? ' active' : '')}>
                                        <i className={"fas fa-home mr-3"}></i> Home
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={"/admin/planejamento"}
                                        className={"btn btn-primary w-100 text-white d-flex align-items-center justify-content-start py-2 rounded-0" + (currentPath == 'minhas-atividades' ? ' active' : '')}
                                        eventKey={1}>
                                        <i className={"far fa-calendar-alt mr-3"}></i> Planejamento
                                    </Link>
                                </li>
                                <li>
                                    <Accordion.Toggle
                                        className={"btn btn-primary w-100 text-white d-flex align-items-center justify-content-start py-2 rounded-0" + (currentPath == 'admin' ? ' active' : '')}
                                        eventKey={1}>
                                        <i className={"fas fa-calendar-alt mr-3"}></i> Usuários
                                        <i className={"fas fa-angle-down small ml-auto"}></i>
                                    </Accordion.Toggle>

                                    <Accordion.Collapse eventKey={1}>
                                        <ul className={"py-2 px-0 ml-2"}>
                                            <li>
                                                <Link to={"/admin/alunos"}
                                                      className={"btn btn-primary w-100 text-white text-left rounded-0" + (currentPath == 'alunos' ? ' active' : '')}>
                                                    <i className={"fa fa-angle-right mr-2"}></i>
                                                    Alunos
                                                </Link>
                                            </li>
                                        </ul>
                                    </Accordion.Collapse>
                                </li>
                            </ul>
                        </Accordion>
                    </nav>

                </aside>
            )
        }

        // Sidebar Aluno
        if (this.state.role === 'aluno') {
            let currentPath = this.props.path.split('/')[1] ? this.props.path.split('/')[1] : ''
            let currentSubPath = this.props.path.split('/')[2] ? this.props.path.split('/')[2] : ''

            return (
                <aside id="sidebar"
                       className={"col-9 px-0 col-md-2 position-fixed h-100 text-white d-flex flex-column"}>

                    <div
                        className={"w-100 bg-light text-white title text-center d-flex align-items-center justify-content-center"}>
                        <Link
                            to={"/"}
                        >
                            <img src={process.env.PUBLIC_URL + '/images/logo192.png'} className={"img-fluid py-3"}
                                 style={{
                                     maxHeight: "70px"
                                 }}/>
                        </Link>
                    </div>

                    <nav className={"p-0 bg-primary h-100"} id="menu">
                        <Accordion defaultActiveKey={0}>
                            <ul className={"mx-0 px-0"}>
                                <li>
                                    <Link
                                        to={"/"}
                                        title=""
                                        className={"btn btn-primary w-100 text-white d-flex align-items-center justify-content-start py-2 rounded-0" + (currentPath == '' ? ' active' : '')}>
                                        <i className={"fas fa-home mr-3"}></i> Home
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={"/minhas-atividades"}
                                        className={"btn btn-primary w-100 text-white d-flex align-items-center justify-content-start py-2 rounded-0" + (currentPath == 'minhas-atividades' ? ' active' : '')}
                                        eventKey={1}>
                                        <i className={"far fa-edit mr-3"}></i> Minhas Atividades
                                        {/*<i className={}"fas fa-angle-down small ml-auto"}></i>*/}
                                    </Link>
                                </li>
                                <li>
                                    <Accordion.Toggle
                                        className={"btn btn-primary w-100 text-white d-flex align-items-center justify-content-start py-2 rounded-0" + (currentPath == 'cursos' ? ' active' : '')}
                                        eventKey={2}>
                                        <i className={"fas fa-calendar-alt mr-3"}></i> Cursos
                                        <i className={"fas fa-angle-down small ml-auto"}></i>
                                    </Accordion.Toggle>

                                    <Accordion.Collapse eventKey={2}>
                                        <ul id={"alunoCursosMenu"} className={"py-2 px-0 ml-2"}>
                                            {this.state.alunoCursos.map((cursoInfo) =>
                                                <li>
                                                    <Link to={"/cursos/" + (cursoInfo._id)}
                                                          className={"btn btn-primary w-100 text-white text-left rounded-0" + (currentSubPath == cursoInfo._id ? ' active' : '')}>
                                                        <i className={"fa fa-angle-right mr-2"}></i>
                                                        <small>{cursoInfo.nome}</small>
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </Accordion.Collapse>
                                </li>
                                {/*<li>*/}
                                {/*    <Link to={"/forum"}*/}
                                {/*          className={"btn btn-primary w-100 text-white d-flex align-items-center justify-content-start py-2 rounded-0" + (currentPath == 'forum' ? ' active' : '')}*/}
                                {/*          eventKey={3}>*/}
                                {/*        <i className={"far fa-comments mr-3"}></i> Forum*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <Link*/}
                                {/*        to={"/central-de-ajuda"}*/}
                                {/*        title=""*/}
                                {/*        className={"btn btn-primary w-100 text-white d-flex align-items-center justify-content-start py-2 rounded-0" + (currentPath == 'central-de-ajuda' ? ' active' : '')}>*/}
                                {/*        <i className={"far fa-question-circle mr-3"}></i> Central de Ajuda*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                            </ul>
                        </Accordion>
                    </nav>

                </aside>
            )
        }
    }
}

export default Sidebar