// CSS
import "./AtividadeCard.css"

// Módulos
import React, {Component} from "react"
import {Link} from "react-router-dom"

class AulaCard extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        let AulaLink = this.props.path + "/live/" + this.props.call._id
        let dateTime = new Date(this.props.call.start_time)
        let dateFormatted =
            (dateTime.getDate() <= 9 ? "0" + dateTime.getDate() : dateTime.getDate()) + "/" +
            ((dateTime.getMonth() + 1) <= 9 ? "0" + (dateTime.getMonth() + 1) : (dateTime.getMonth() + 1)) + "/" +
            dateTime.getFullYear()
        let hourFormatted =
            (dateTime.getHours() <= 9 ? "0" + dateTime.getHours() : dateTime.getHours()) + "h" +
            (dateTime.getMinutes() <= 9 ? "0" + dateTime.getMinutes() : dateTime.getMinutes())

        return (
            <Link to={AulaLink} className="card-activity-student">
                <div className="bg-white border-radius border p-2 card-body mb-3">
                    <div className="p-3 bg-primary">
                        <h3 className="title text-white">
                            {this.props.call.topic}
                        </h3>
                        <p className="discipline text-white">Matéria: {this.props.materia}</p>
                    </div>
                    <div className="p-1 py-0 bg-white info text-secondary">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-md-6 text-center text-md-left mt-2">
                                    <span className="text-dark">Data e hora da Live</span><br/>
                                    {dateFormatted} às <strong className={"d-block"}>{hourFormatted}</strong>
                                </div>
                                <div className="col-12">
                                    <hr className="my-3"/>
                                </div>
                                <div className="col-12 text-center font-weight-bold access-activity text-uppercase">
                                    <i className="fa fa-angle-right mr-1"></i> Assistir à aula
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        )
    }
}

export default AulaCard