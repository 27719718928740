// CSS
import "./Atalhos.css"

// Módulos
import React, {Component} from "react"
import {Link} from "react-router-dom"

class Atalhos extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="col-12 col-md-6 pr-md-5" id="shortcuts">

                <div className="container-fluid">
                    <div className="row align-items-stretch">

                        <Link to={"/minhas-atividades"} className="col-12 text-center py-5 mb-3 btn-sc-activities bg-primary text-white p-2"
                           href="">
                            <h1 className="font-weight-bold mb-3"><i className="far fa-calendar-alt fa-lg mr-1"></i> Calendário</h1>
                            <div className={"small"}>Faça o agendamento de suas entregas para não perder nada!</div>
                        </Link>

                        {/*<div className="col-12 col-md-6 px-0 pl-md-0 pr-md-2">*/}
                        {/*    <Link to={"/meus-arquivos"} className="d-block w-100 text-center py-5 mb-3 mb-md-0 btn-sc-files bg-secondary text-white p-2"*/}
                        {/*       href="">*/}
                        {/*        <h1 className="font-weight-bold mb-3"><i className="far fa-file fa-lg mr-1"></i> Arquivos</h1>*/}
                        {/*        <div className={"small"}>Arquivos disponibilizados à você pela coordenação do curso</div>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}

                        {/*<div className="col-12 col-md-6 px-0 pr-md-0 pl-md-2">*/}
                        {/*    <Link to={"/tutoriais"} className="d-block w-100 text-center py-5 btn-sc-tutorials bg-success text-white p-2"*/}
                        {/*       href="">*/}
                        {/*        <h1 className="font-weight-bold mb-3"><i className="fa fa-photo-video fa-lg mr-1"></i> Tutoriais</h1>*/}
                        {/*        <div className={"small"}>Tutoriais e instruções de como utilizar a nossa plataforma</div>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}

                    </div>
                </div>

            </div>
        )
    }
}

export default Atalhos