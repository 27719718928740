// CSS
import './Login.css'

// Módulos
import React, {Component} from "react"
import {Redirect} from 'react-router-dom'
import Axios from 'axios'
import ReactLoading from "react-loading";

class LoginView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            islogged: false,
            done: true,
            alertClass: "alert alert-danger alert-dismissible mt-4 fade show d-none",
            loginParams: {
                user_username: "",
                user_password: ""
            }
        }
    }

    handleFormChange = event => {
        let loginParamsNew = {...this.state.loginParams}
        let val = event.target.value
        loginParamsNew[event.target.name] = val
        this.setState({
            loginParams: loginParamsNew
        })
    }

    login = event => {
        this.setState({
            done: false
        })

        let user_username = this.state.loginParams.user_username
        let user_password = this.state.loginParams.user_password

        Axios.post('https://serviceperfil.octaead.com.br/login', {
            username: user_username,
            password: user_password
        })
            .then(async (loginData) => {
                let nomeArr = loginData.data.nome.split(" ")
                let userData = {
                    nome: nomeArr[0],
                    sobrenome: loginData.data.nome.replace(nomeArr[0], "").trim()
                }

                if (loginData.data.role !== "aluno") {
                    let zoomUserInfo = await Axios.post('https://servicecall.octaead.com.br/user-info/' + loginData.data.email, userData)
                    localStorage.setItem('zoomUserId', zoomUserInfo.data.id)
                }

                if (loginData.data.role === "aluno") {
                    let turmaInfo = await Axios.post('https://serviceturma.octaead.com.br/turmas-filter', {
                        alunos: loginData.data._id
                    })

                    if (turmaInfo.data.length > 0) {
                        localStorage.setItem("turmaId", turmaInfo.data[0]._id)
                    }
                }

                localStorage.setItem("token", loginData.data._id)
                localStorage.setItem("userId", loginData.data._id)
                localStorage.setItem("userRole", loginData.data.role)
                localStorage.setItem("userName", nomeArr[0])
                localStorage.setItem("userFoto", loginData.data.foto)

                this.setState({
                    islogged: true,
                    done: true
                })
            })
            .catch((err) => {
                this.setState({
                    loginError: true,
                    alertClass: "alert alert-danger alert-dismissible m-3 mt-0 fade show w-100"
                })
            })

        event.preventDefault()
    }

    render() {
        if (localStorage.getItem("token")) {
            if (localStorage.getItem("userRole") !== "aluno") {
                return <Redirect to="/admin"/>
            } else {
                return <Redirect to="/"/>
            }
        }

        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <main id="login">

                <div className="container-fluid h-100 position-absolute">
                    <div className="row h-100">

                        <div className="col-12 col-md-4 h-100 d-flex align-items-center">
                            <form className="container-fluid" onSubmit={this.login}>

                                <div className="row d-flex justify-content-center">
                                    <div className="col-5 mb-5 text-center">
                                        <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                                             className={"img-fluid"}/>
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="col-12 mb-3">
                                        <input
                                            type="text"
                                            name="user_username"
                                            id="username"
                                            className="form-control px-3 py-4 user"
                                            placeholder="E-mail"
                                            autoFocus={true}
                                            onChange={this.handleFormChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <input
                                            type="password"
                                            name="user_password"
                                            id="password"
                                            className="form-control px-3 py-4 pass"
                                            placeholder="Senha"
                                            onChange={this.handleFormChange}
                                            required
                                        />
                                    </div>

                                    <div className="col-12 mb-3">
                                        <button type="submit"
                                                className="btn btn-primary text-white text-uppercase h4 w-100 p-3">
                                            Entrar
                                        </button>
                                    </div>

                                    <div className={this.state.alertClass} role="alert">
                                        <strong>Erro!</strong> Nome de usuário ou senha inválidos.
                                    </div>

                                    <div className="col-12 pt-md-1 mb-5 text-right">
                                        <a href="">Esqueceu a senha?</a>
                                    </div>

                                    <div className="col-12 mt-4 text-center">
                                        <a href="https://facebook.com/" target="_blank">
                                            <i className="fab fa-facebook-f fa-lg mx-2"></i>
                                        </a>
                                        <a href="https://instagram.com/" target="_blank">
                                            <i className="fab fa-instagram fa-lg mx-2"></i>
                                        </a>
                                        <a href="https://linkedin.com/" target="_blank">
                                            <i className="fab fa-linkedin-in fa-lg mx-2"></i>
                                        </a>
                                    </div>

                                </div>

                            </form>
                        </div>

                        <div className="col-12 col-md-8 px-md-5 py-md-5 h-100 d-md-flex align-items-md-end cover d-none"
                             style={{
                                 backgroundImage: "url('" + process.env.PUBLIC_URL + "/images/background_login.png"
                             }}>
                            <h2 className="h1 font-weight-bold text-white mb-md-5">
                                Ambiente virtual<br/>de aprendizagem
                            </h2>
                        </div>

                    </div>
                </div>

            </main>
        )
    }
}

export default LoginView
