// Módulos
import React, {Component} from "react"

class QuestaoAlternativa extends Component {
    constructor(props) {
        super(props)

        this.state = {
            alternativas: [],
            universalCounter: 0,
            thisCounter: props.counter
        }

        this.addAlternativa = this.addAlternativa.bind(this)
    }

    addAlternativa = (event) => {
        let alternativas = this.state.alternativas
        let n = this.state.thisCounter
        let i = this.state.universalCounter

        alternativas.push(
            <div className={"card p-2 alternativa-item"} data-key={n} key={i}>
                <div className="row align-items-center">
                    <div className="col-1 d-flex align-items-center justify-content-center">
                        <input type="radio" name={"alternativa_" + n} className={"custom-checkbox"}/>
                    </div>
                    <div className="col-10">
                        <input type="text" name={"titulo_" + n} className={"form-control w-100"}/>
                    </div>
                    <div className="col-1 d-flex align-items-center justify-content-center">
                        <button className="btn btn-danger btn-sm" onClick={() => this.removeAlternativa(i)}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
        )

        this.setState({
            alternativas: alternativas,
            universalCounter: i + 1
        })
    }

    removeAlternativa = (key) => {
        let alternativas = this.state.alternativas
        alternativas.map((item, i) => {
            if (item.key == key) {
                alternativas.splice(i, 1)
            }
        })

        this.setState({
            alternativas: alternativas
        })
    }

    render() {
        return (
            <div className="d-block card">
                <div className="card-body bg-light p-3">
                    <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">
                        Preencha as Alternativas marcando qual é a correta
                    </h6>
                    <div className="d-block">
                        {this.state.alternativas}
                    </div>
                    <hr className="mb-3"/>
                    <div className="d-block text-right">
                        <button
                            onClick={(event) => this.addAlternativa(event)}
                            className="btn btn-primary text-white">
                            Nova alternativa
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default QuestaoAlternativa