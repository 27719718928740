// CSS
import "./Header.css"

// Módulos
import React, {Component} from "react"
import {Dropdown} from "react-bootstrap"
import {Link, Redirect} from "react-router-dom"
import Axios from "axios"

class Header extends Component {
    constructor(props) {
        super(props)

        this.state = {
            islogout: false,
            countAtividadesNovas: 0,
            notificacoesSistema: [],
            notificacoesAtividades: []
        }
    }

    async loadAtividades(turmaId) {
        let Atividades = []
        let atividadesResponseData = await Axios.post(
            'https://serviceatividade.octaead.com.br/atividades-filter',
            {
                $and: [
                    {turmas: turmaId}
                ]
            })

        Atividades.push(atividadesResponseData.data)

        return Atividades
    }

    signOut = () => {
        this.setState({
            islogout: true
        });

        localStorage.clear()
        window.location.href = "/"
    }

    async componentDidMount() {
        let userRole = localStorage.getItem('userRole')
        let userId = localStorage.getItem('userId')
        let userInfo = await Axios.get('https://serviceperfil.octaead.com.br/' + userRole + '/' + userId)
        let Atividades = await this.loadAtividades(localStorage.getItem('turmaId'))
        let userNotificacoes = userInfo.data.notificacoes ? userInfo.data.notificacoes : []
        let notificacoesArr = this.state.notificacoesSistema
        let notificacoesAtividadesArr = this.state.notificacoesAtividades
        let currentCount = this.state.countAtividadesNovas

        for (let n in userNotificacoes) {
            let notificacaoData = await Axios.get('https://servicenotificacao.octaead.com.br/notificacao/' + userNotificacoes[n])

            notificacoesArr.push(
                <Dropdown.Item as={Link} to={notificacaoData.data.link} className="p-2 mb-1 text-wrap btn btn-light">
                    <span className={"d-block"}>
                        {notificacaoData.data.titulo}
                    </span>
                    <small className="d-block mt-1">
                        {notificacaoData.data.descricao}
                    </small>
                    <span className="mt-3 small text-primary">Clique para ver</span>
                </Dropdown.Item>
            )

            if (n < (userNotificacoes.length - 1)) {
                notificacoesArr.push(<Dropdown.Divider/>)
            }
        }

        if (notificacoesArr.length <= 0) {
            notificacoesArr = (
                <Dropdown.Item className="p-2 mb-1 text-wrap text-center btn btn-light small">
                    Você não tem notificações :)
                </Dropdown.Item>
            )
        } else {
            notificacoesArr.reverse()
        }

        Atividades = Atividades[0]
        for (let a in Atividades) {
            let DataFinal = new Date(Atividades[a].data_termino)
            let DataAvisoNova = new Date(Atividades[a].data_inicio)
            let Today = new Date()
            let Flag = null

            DataFinal =
                ((DataFinal.getDate() + 1) <= 9 ? "0" + (DataFinal.getDate() + 1) : (DataFinal.getDate() + 1)) + "/" +
                ((DataFinal.getDate() + 1) <= 9 ? "0" + (DataFinal.getMonth() + 1) : (DataFinal.getMonth() + 1)) + "/" +
                DataFinal.getFullYear()

            // 30 dias para mostrar o aviso de ativiade nova
            DataAvisoNova.setDate(DataAvisoNova.getDate() + 30)

            if (Today <= DataAvisoNova) {
                currentCount = currentCount + 1
                Flag =
                    <div className="ballon-new bg-success text-white px-2 small d-inline float-right">
                        <div className="arrow-right"></div>
                        Nova
                    </div>
            }
            notificacoesAtividadesArr.push(
                <Dropdown.Item as={Link} to={"/cursos/" + Atividades[a]['cursos'][0] + "/atividade/" + Atividades[a]._id}
                               className="p-2 mb-1 text-wrap btn btn-light">
                    <span className={"d-block"}>
                        {Flag}
                        {Atividades[a].titulo}
                        <small className={"d-block"}>Encerra em {DataFinal} às 23:59</small>
                    </span>
                    <span className="mt-3 small text-primary">ir para a atividade</span>
                </Dropdown.Item>
            )

            if (a < (Atividades.length - 1)) {
                notificacoesAtividadesArr.push(<Dropdown.Divider/>)
            }
        }

        if (notificacoesAtividadesArr.length <= 0) {
            notificacoesAtividadesArr = (
                <Dropdown.Item className="p-2 mb-1 text-wrap text-center btn btn-light small">
                    Você não tem atividades :)
                </Dropdown.Item>
            )
        } else {
            notificacoesAtividadesArr.reverse()
        }

        this.setState({
            notificacoesSistema: notificacoesArr,
            notificacoesAtividades: notificacoesAtividadesArr,
            countAtividadesNovas: currentCount
        })
    }

    render() {
        return (
            <header className="w-100 bg-white sticky-top" id="header-restrict-area">
                <div className="container-fluid">
                    <div className="row py-0 py-md-2 py-md-auto d-flex align-items-center">
                        <div className="col-1 px-0 d-block d-md-none">
                            <i className="fas fa-bars fa-2x text-secondary toggle-button"></i>
                        </div>

                        <div className="col-9 col-md-9 text-center text-md-left">
                            <div className="d-flex">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        variant={"light"}
                                        className={"d-md-flex align-items-md-center mx-2"}
                                        id={"drop-notificacoes"}
                                    >
                                        <i className="far fa-comment-alt text-primary mr-2"></i>
                                        {this.state.notificacoesSistema.length > 0 ? (
                                            <span
                                                className="notify bg-danger text-white">{Math.ceil(this.state.notificacoesSistema.length / 2)}</span>
                                        ) : null}
                                        <span className="d-none d-md-block">Notificações</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className={"py-0 notifies"}>
                                        {this.state.notificacoesSistema}
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Dropdown>
                                    <Dropdown.Toggle
                                        variant={"light"}
                                        className={"d-md-flex align-items-md-center mx-2"}
                                        id={"drop-atividades"}
                                    >
                                        <i className="fas fa-clipboard-list text-primary mr-2"></i>
                                        {this.state.countAtividadesNovas > 0 ? (
                                            <span
                                                className="notify bg-danger text-white">{this.state.countAtividadesNovas}</span>
                                        ) : null}

                                        <span className="d-none d-md-block">Atividades</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className={"py-0 notifies"}>
                                        {this.state.notificacoesAtividades}
                                    </Dropdown.Menu>
                                </Dropdown>

                                {/*<Dropdown>*/}
                                {/*    <Dropdown.Toggle variant={"light"}*/}
                                {/*                     className={"d-md-flex align-items-md-center mx-2"}*/}
                                {/*                     id={"drop-mensagens"}>*/}
                                {/*        <i className="far fa-envelope text-primary mr-2"></i>*/}
                                {/*        <span className="notify bg-danger text-white">+9</span>*/}

                                {/*        <span className="d-none d-md-block">Mensagens</span>*/}
                                {/*    </Dropdown.Toggle>*/}

                                {/*    <Dropdown.Menu className={"py-0 notifies"}>*/}
                                {/*        <Dropdown.Item className="p-2 mb-1 text-wrap btn btn-light">*/}
                                {/*            <small className={"d-block"}>*/}
                                {/*                Olá Rafael aqui é o professor Rodrigo preciso que você responda a*/}
                                {/*                atividade de geografia até dia 20.06*/}
                                {/*            </small>*/}
                                {/*            <a href="" className="mt-3 small">Responder mensagem</a>*/}
                                {/*        </Dropdown.Item>*/}
                                {/*    </Dropdown.Menu>*/}
                                {/*</Dropdown>*/}

                            </div>
                        </div>

                        <div
                            className="user-info col-2 px-0 px-md-auto col-md-3 d-flex align-items-center justify-content-end float-right text-light-purple-2">

                            <Dropdown>
                                <Dropdown.Toggle variant={"light"} className={"d-md-flex align-items-md-center mx-2"}
                                                 id={"drop-user"}>
                                    <span className="d-none d-md-block">
                                        {localStorage.getItem('userName')}
                                    </span>
                                    <img src={localStorage.getItem('userFoto')}
                                         alt={localStorage.getItem('userName').substring(0, 2)}
                                         title={localStorage.getItem('userName').substring(0, 2)}
                                         className="profile-image mx-md-2 border-radius text-white d-flex align-items-center justify-content-center "/>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={"p-2"} alignRight>
                                    <Dropdown.Item
                                        as={Link}
                                        to={localStorage.getItem("userRole") == "aluno" ? "/meu-perfil" : "/admin/editar-perfil"}
                                        className="p-2 mb-1 text-wrap btn btn-light btn-sm"
                                    >
                                        <i className="fa fa-user mr-3"></i>
                                        Meus dados pessoais
                                    </Dropdown.Item>
                                    <Dropdown.Divider/>
                                    <Dropdown.Item
                                        className="p-2 mb-1 text-wrap btn btn-light btn-sm"
                                        onClick={this.signOut}
                                    >
                                        <i className="fa fa-sign-out-alt mr-3"></i>
                                        Sair
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header