// CSS
import "./AtividadeCard.css"

// Módulos
import React, {Component} from "react"
import {Link} from "react-router-dom"

class AtividadeCard extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        let Flag = null
        let DataInicio = new Date(this.props.atividadeData.data_inicio)
        let DataTermino = new Date(this.props.atividadeData.data_termino)
        let DataAvisoAtencao = new Date(this.props.atividadeData.data_termino)
        let DataAvisoNova = new Date(this.props.atividadeData.data_inicio)
        let Today = new Date()

        // 15 dias para mostrar o aviso de atenção
        DataAvisoAtencao.setDate(DataAvisoAtencao.getDate() - 15)

        // 30 dias para mostrar o aviso de ativiade nova
        DataAvisoNova.setDate(DataAvisoNova.getDate() + 30)

        if (Today <= DataAvisoNova) {
            Flag = <span className="py-1 px-2 bg-success text-white notification new-activity"><div
                className="arrow-right"></div>Nova</span>
        }

        if (Today >= DataAvisoAtencao) {
            Flag = <span className="py-1 px-2 bg-danger text-white notification activity-in-atention"><div
                className="arrow-right"></div>Atenção</span>
        }

        let AtividadeLink = this.props.path + "/atividade" + "/" + this.props.atividadeData._id

        DataInicio = DataInicio.getDate() + "/" + ((DataInicio.getMonth() + 1) <= 9 ? "0" + (DataInicio.getMonth() + 1) : (DataInicio.getMonth() + 1)) + "/" + DataInicio.getFullYear()
        DataTermino = DataTermino.getDate() + "/" + ((DataTermino.getMonth() + 1) <= 9 ? "0" + (DataTermino.getMonth() + 1) : (DataTermino.getMonth() + 1)) + "/" + DataTermino.getFullYear()

        return (
            <Link to={AtividadeLink} className="card-activity-student col-12 col-sm-6 col-md-4 px-2 mb-4">
                <div className="bg-white mb-3 border-radius border p-2 card-body">
                    <div className="p-3 bg-primary">
                        {Flag}
                        <h3 className="title text-white">
                            {this.props.atividadeData.titulo}
                        </h3>
                        <p className="discipline text-white">Matéria: {this.props.materia}</p>
                    </div>
                    <div className="p-1 py-0 bg-white info text-secondary">
                        <div className="container-fluid">
                            <div className="row mt-2">
                                <div className="col-12 col-md-6 text-center text-md-left">
                                    <span className="text-dark">Início</span><br/>
                                    {DataInicio}
                                </div>
                                <div className="col-12 col-md-6 text-center text-md-right">
                                    <span className="text-dark">Término</span><br/>
                                    {DataTermino}
                                </div>
                                <div className="col-12">
                                    <hr className="my-3"/>
                                </div>
                                <div className="col-12 text-center font-weight-bold access-activity text-uppercase">
                                    <i className="fa fa-angle-right mr-1"></i> Acessar Atividade
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        )
    }
}

export default AtividadeCard