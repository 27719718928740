// CSS
import "./UltimasAtividades.css"

// Módulos
import React, {Component} from "react"
import ReactLoading from "react-loading"

// Components
import AtividadeHomeCard from "./AtividadeHomeCard"
import Axios from "axios"

class UltimasAtividades extends Component {
    constructor(props) {
        super(props)

        this.state = {
            done: false,
            Atividades: null
        }
    }

    async loadAtividades(turmaId) {
        let Atividades = []
        let atividadesResponseData = await Axios.post(
            'https://serviceatividade.octaead.com.br/atividades-filter',
            {
                $and: [
                    {turmas: turmaId}
                ]
            })

        Atividades.push(atividadesResponseData.data)

        return Atividades
    }

    async mountAtividades(Atividades) {
        let returnArr = []

        await Promise.all(
            Atividades[0].map((atividade, j) => {

                if (atividade.respostas.indexOf(localStorage.getItem('userId')) < 0) {
                    returnArr.push(
                        <AtividadeHomeCard
                            key={j}
                            atividadeData={atividade}
                        />
                    )
                }
            })
        )

        if (returnArr.length <= 0) {
            returnArr = (
                <div className={"card p-3 w-100"}>
                    Parabéns! Você não tem atividades para fazer.
                </div>
            )
        }

        return returnArr
    }

    async componentDidMount() {
        let Atividades = await this.loadAtividades(localStorage.getItem('turmaId'))
        let MountAtividades = await this.mountAtividades(Atividades)

        this.setState({
            done: true,
            Atividades: MountAtividades
        })
    }

    render() {
        return !this.state.done ? (
            <section id="sidebar-right" className="col-12 col-md-4">
                <div id="labels-activities" className="w-100 p-3 bg-white p-3 border border-radius mb-3">
                    <div className="d-flex align-items-center justify-content-center h-100">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                        </div>
                    </div>
                </div>
            </section>
        ) : (
            <div className="col-12 col-md-6 px-3 px-md-5" id="last-activities">
                <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">Últimas Atividades</h6>
                {this.state.Atividades}
            </div>
        )
    }
}

export default UltimasAtividades