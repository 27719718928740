// CSS
import "./AlunoAtividadeView.css"

// Módulos
import React, {Component} from "react"
import {Link} from "react-router-dom"
import ReactLoading from "react-loading";
import jQuery from "jquery"

class AlunoAtividadeView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            done: false
        }
    }

    chooseAlternative = (questao, indexAlternativa) => {
        this.props.chooseAlternative(questao, indexAlternativa)
    }

    componentDidMount() {
        this.setState({
            done: true
        })
    }

    render() {
        let Alternativas = []

        if (this.props.type == 'questionario_simples' || this.props.type == 'simulado') {
            this.props.questao.alternativas.map((alternativa, k) => {
                Alternativas.push(
                    <li
                        className={"alternative border p-3 mb-3 d-flex align-items-center border-radius " + (alternativa.selecionada ? 'selected' : '')}
                        onClick={() => this.chooseAlternative(this.props.questao, k)}
                        key={k}
                    >
                        <span className="circle mr-3"></span> {alternativa.titulo}
                    </li>
                )
            })
        }

        //  = this.props.type !== "dissertativa" ? () : (
        //     <div className="dissertation-answer bg-white p-3 border border-radius mb-4">
        //
        //         <h1 className="font-weight-bold mb-4">Resposta</h1>
        //
        //         <textarea
        //             name="resposta"
        //             rows="8"
        //             className="form-control p-3 resposta"
        //             placeholder="Digite sua resposta aqui..."
        //         />
        //
        //     </div>
        // )

        return !this.state.done ? (
            <section
                id="questions"
                className="col-12 col-md-8"
            >
                <div className="question bg-white p-3 border border-radius mb-4">
                    <div className="d-flex align-items-center justify-content-center h-100">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                        </div>
                    </div>
                </div>
            </section>
        ) : (
            <section
                id="questions"
                className="col-12 col-md-8"
            >
                <div className="question bg-white p-3 border border-radius mb-4">
                    <h1 className="font-weight-bold mb-3">{this.props.questao.titulo}</h1>
                    <div dangerouslySetInnerHTML={{__html: this.props.questao.enunciado}}/>
                </div>

                {this.props.type == 'questionario_simples' || this.props.type == 'simulado' ? (
                    <div className="alternative-answer bg-white p-3 border border-radius mb-4">
                        <h1 className="font-weight-bold mb-4">Alternativas</h1>
                        <ul className="px-0 alternatives">
                            {Alternativas}
                        </ul>
                    </div>
                ) : null}
            </section>
        )
    }
}

export default AlunoAtividadeView