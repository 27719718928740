// CSS
import "./AlunoAtividadeComplexView.css"

// Módulos
import React, {Component} from "react"
import {Link} from "react-router-dom"
import ReactLoading from "react-loading"
import Axios from "axios"
import jQuery from "jquery"
import SweetAlert from "sweetalert"

// Components
import SidebarRight from "../../components/SidebarRight";

// Views
import AlunoAtividadeView from "./AlunoAtividadeView"

class AlunoAtividadeComplexView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            done: false,
            atividadeData: {}
        }
    }

    prevQuestion = (e) => {
        e.preventDefault()
        jQuery('.next-question').removeClass('disabled')
        let currentQuestion = this.state.questoes.indexOf(this.state.questaoAtual)
        let newIndex = currentQuestion - 1

        if (newIndex <= 0) {
            newIndex = 0
            jQuery('.prev-question').addClass('disabled')
        }

        this.setState({
            questaoAtual: this.state.questoes[newIndex]
        })
    }

    nextQuestion = (e) => {
        e.preventDefault()
        jQuery('.prev-question').removeClass('disabled')
        let currentQuestion = this.state.questoes.indexOf(this.state.questaoAtual)
        let newIndex = currentQuestion + 1

        if (newIndex >= this.state.questoes.length - 1) {
            newIndex = this.state.questoes.length - 1
            jQuery('.next-question').addClass('disabled')
        }

        this.setState({
            questaoAtual: this.state.questoes[newIndex]
        })
    }

    changeQuestion = (index) => {
        this.setState({
            questaoAtual: this.state.questoes[index]
        })
    }

    chooseAlternative = (questao, indexAlternativa) => {
        let questoes = this.state.questoes
        let questaoAtual = questoes.indexOf(questao)

        questoes[questaoAtual].alternativas.map((alternativa, i) => {
            if (i == indexAlternativa) {
                questoes[questaoAtual].alternativas[i]['selecionada'] = true
            } else {
                delete questoes[questaoAtual].alternativas[i]['selecionada']
            }
        })

        this.setState({
            questoes: questoes
        })
    }

    finish = () => {
        let questoes = this.state.questoes
        let countQuestions = questoes.length
        let countAnswers = 0

        questoes.map((questao, q) => {
            questao.alternativas.map((alternativa, a) => {
                if (alternativa.selecionada) {
                    countAnswers = countAnswers + 1
                }
            })
        })

        if (countAnswers < countQuestions) {
            SweetAlert({
                title: "Atenção!",
                text: "Você ainda não respondeu todas as questões! Tem certeza que quer finalizar o seu questionário?",
                icon: "error",
                dangerMode: true,
                buttons: {
                    cancel: "Mudei de idéia",
                    confirm: "Sim, quero finalizar"
                }
            })
                .then((choose) => {
                    if (choose) {
                        this.saveData()
                    }
                })
        } else {
            this.saveData()
        }
    }

    saveData = () => {
        SweetAlert({
            title: "Um momento",
            text: "Estamos salvamos as suas respostas"
        })

        let atividadeId = this.state.atividadeData._id
        let respostas = this.state.atividadeData.respostas ? this.state.atividadeData.respostas : []
        respostas.push(localStorage.getItem('userId'))

        Axios.patch('https://serviceatividade.octaead.com.br/atividade/' + atividadeId, {
            respostas: respostas
        })
            .then(() => {
                SweetAlert({
                    title: "Tudo certo",
                    text: "Suas respostas foram salvas em nosso sistema",
                    icon: 'success'
                })
            })
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            let atividadeData = await Axios.get('https://serviceatividade.octaead.com.br/atividade/' + this.props.match.params.atividadeId)
            let professorInfo = await Axios.get('https://serviceperfil.octaead.com.br/professor/' + atividadeData.data.professores[0])
            let cursoInfo = await Axios.get('https://servicecurso.octaead.com.br/curso/' + this.props.match.params.id)

            this.setState({
                done: true,
                atividadeData: atividadeData.data,
                questoes: atividadeData.data.questoes,
                questaoAtual: atividadeData.data.questoes[0],
                professorInfo: professorInfo.data,
                cursoInfo: cursoInfo.data
            })
        }
    }

    async componentDidMount() {
        let atividadeData = await Axios.get('https://serviceatividade.octaead.com.br/atividade/' + this.props.match.params.atividadeId)
        let professorInfo = await Axios.get('https://serviceperfil.octaead.com.br/professor/' + atividadeData.data.professores[0])
        let cursoInfo = await Axios.get('https://servicecurso.octaead.com.br/curso/' + this.props.match.params.id)

        this.setState({
            done: true,
            atividadeData: atividadeData.data,
            questoes: atividadeData.data.questoes,
            questaoAtual: atividadeData.data.questoes[0],
            professorInfo: professorInfo.data,
            cursoInfo: cursoInfo.data
        })
    }

    render() {
        let DataInicio = new Date(this.state.atividadeData.data_inicio)
        let DataTermino = new Date(this.state.atividadeData.data_termino)
        let DataCorrecao = new Date(this.state.atividadeData.data_resultado)

        DataInicio = DataInicio.getDate() + "/" + ((DataInicio.getMonth() + 1) <= 9 ? "0" + (DataInicio.getMonth() + 1) : (DataInicio.getMonth() + 1)) + "/" + DataInicio.getFullYear()
        DataTermino = DataTermino.getDate() + "/" + ((DataTermino.getMonth() + 1) <= 9 ? "0" + (DataTermino.getMonth() + 1) : (DataTermino.getMonth() + 1)) + "/" + DataTermino.getFullYear()
        DataCorrecao = DataCorrecao.getDate() + "/" + ((DataCorrecao.getMonth() + 1) <= 9 ? "0" + (DataCorrecao.getMonth() + 1) : (DataCorrecao.getMonth() + 1)) + "/" + DataCorrecao.getFullYear()

        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <main>
                <section
                    id="header-activity"
                    className="px-3 px-md-5 my-4"
                >
                    <div className="container-fluid bg-white border-light-gray-3 border-radius p-3">
                        <div className="row">
                            <div className="col-12 mb-3 pb-2 border-bottom">
                                <h1 className="font-weight-bold text-uppercase">
                                    {this.state.atividadeData.titulo}
                                </h1>
                            </div>

                            <div className="col-12 col-md-4 mb-3">
                                <h2 className="font-weight-bold mb-1">Período</h2>
                                <p>{DataInicio} 00:00 a {DataTermino} 23:59</p>
                            </div>

                            <div className="col-12 col-md-6 mb-3">
                                <h2 className="font-weight-bold mb-1">Data de divulgação do resultado</h2>
                                <p>{DataCorrecao}</p>
                            </div>

                            <div className="col-12 col-md-2 mb-3">
                                <h2 className="font-weight-bold mb-1">Status</h2>
                                <p>{this.state.atividadeData.status == 1 ? (
                                    'ABERTO'
                                ) : (this.state.atividadeData.status == 0 ? (
                                    'ENCERRADO'
                                ) : (
                                    'AGUARDANDO'
                                ))}</p>
                            </div>

                            <div className="col-12 col-md-4">
                                <h2 className="font-weight-bold mb-1">Professor responsável</h2>
                                <p>{this.state.professorInfo.nome}</p>
                            </div>

                            <div className="col-12 col-md-4">
                                <h2 className="font-weight-bold mb-1">Curso</h2>
                                <p>{this.state.cursoInfo.codigo} - {this.state.cursoInfo.nome}</p>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="container-fluid px-3 px-md-5">
                    <div className="row">
                        <AlunoAtividadeView
                            questao={this.state.questaoAtual}
                            type={this.state.atividadeData.tipo_atividade}
                            chooseAlternative={this.chooseAlternative}
                        />
                        <SidebarRight
                            questoes={this.state.questoes}
                            questaoAtual={this.state.questoes.indexOf(this.state.questaoAtual)}
                            onChange={this.changeQuestion}
                        />

                        <div className="col-12 mt-4">
                            <nav className="container-fluid px-md-0 nav-question">
                                <div className="row px-md-0 d-md-flex align-items-md-center">
                                    <div
                                        className="col-12 col-md-4 col-lg-3 font-weight-bold text-uppercase d-flex align-items-center justify-content-start mb-3 mb-md-0">
                                        <span className="indicator">
                                            Questão {this.state.questoes.indexOf(this.state.questaoAtual) + 1} de {this.state.questoes.length}
                                        </span>

                                        <div className="d-block ml-3">
                                            <a
                                                href={"#"}
                                                className="px-2 py-1 bg-primary text-white prev-question cursor-pointer disabled"
                                                onClick={this.prevQuestion}
                                            >
                                                <i className="fas fa-angle-left"></i>
                                            </a>
                                            <a
                                                href={"#"}
                                                className="ml-1 px-2 py-1 bg-primary text-white next-question cursor-pointer"
                                                onClick={this.nextQuestion}
                                            >
                                                <i className="fas fa-angle-right"></i>
                                            </a>
                                        </div>

                                    </div>

                                    <div className="col-12 col-md-4 col-lg-3 mb-3 mb-md-0">
                                        <div
                                            onClick={this.prevQuestion}
                                            className="prev-question w-100 btn border-radius py-3 text-center text-white bg-secondary font-weight-bold disabled"
                                        >
                                            <i className="fas fa-angle-left"></i> Questão Anterior
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 col-lg-3 mb-3 mb-md-0">
                                        <div
                                            onClick={this.nextQuestion}
                                            className="next-question w-100 btn border-radius py-3 text-center bg-secondary text-white font-weight-bold cursor-pointer"
                                        >
                                            Próxima questão <i className="fas fa-angle-right"></i>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 col-lg-3 mb-3 mb-md-0 ml-auto">
                                        <div
                                            onClick={this.finish}
                                            className="w-100 btn border-radius py-3 text-center bg-success text-white font-weight-bold cursor-pointer"
                                        >
                                            Finalizar
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>

                    </div>
                </div>
            </main>
        )
    }
}

export default AlunoAtividadeComplexView