import React, {Component} from "react"
import Axios from "axios"
import jQuery from 'jquery'
import SweetAlert from 'sweetalert'
import ReactLoading from "react-loading"
import {Link} from "react-router-dom"
import MaskedInput from "react-text-mask"

class TurmasCadastroView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            done: false,
            fields: {
                codigo: "",
                independente: "false",
                nome: "",
                vagas: 0,
                dataInicio: "",
                dataTermino: "",
                observacoes: "",
                cursos: [],
                coordenadores: [],
                professores: [],
                alunos: [],
                unidade: ""
            }
        }
    }

    cancelarCadastro = (event) => {
        event.preventDefault()

        SweetAlert({
            title: "Atenção!",
            text: "Tem certeza de que deseja cancelar? As informações digitadas serão perdidas!",
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: "Mudei de idéia",
                confirm: "Sim, quero cancelar"
            }
        })
            .then((response) => {
                if (response) {
                    window.location.href = event.target.href
                }
            })
    }

    salvarCadastro = async (event) => {
        event.preventDefault()

        if (
            this.state.fields.codigo.length <= 0 ||
            this.state.fields.unidade.length <= 0 ||
            this.state.fields.nome.length <= 0 ||
            this.state.fields.vagas <= 0
        ) {
            SweetAlert(
                "Erro",
                "Por favor, verifique se o codigo, nome, unidade e vagas estão preenchidos corretamente."
            )
        } else {
            SweetAlert({
                text: "Tudo certo! Agora vamos salvar o cadastro.",
                button: {
                    text: "Ok, vou aguardar"
                }
            })

            let coordenadoresArr = []
            let professoresArr = []
            let cursosArr = []
            let alunosArr = []

            jQuery('#coordenadoresContainer option').each((index, item) => {
                if (jQuery(item).is(':selected')) {
                    coordenadoresArr.push(jQuery(item).val())
                }
            })
            jQuery('#professoresContainer option').each((index, item) => {
                if (jQuery(item).is(':selected')) {
                    professoresArr.push(jQuery(item).val())
                }
            })
            jQuery('#cursosContainer option').each((index, item) => {
                if (jQuery(item).is(':selected')) {
                    cursosArr.push(jQuery(item).val())
                }
            })
            jQuery('#alunosContainer option').each((index, item) => {
                if (jQuery(item).is(':selected')) {
                    alunosArr.push(jQuery(item).val())
                }
            })

            let unidadeId = this.state.fields.unidade

            await this.setState(prevState => ({
                ...prevState,
                fields: {
                    codigo: this.state.fields.codigo,
                    nome: this.state.fields.nome,
                    independente: this.state.fields.independente,
                    vagas: this.state.fields.vagas - alunosArr.length,
                    dataInicio: new Date(this.state.fields.dataInicio),
                    dataTermino: new Date(this.state.fields.dataTermino),
                    observacoes: this.state.fields.observacoes,
                    coordenadores: coordenadoresArr,
                    professores: professoresArr,
                    cursos: cursosArr,
                    alunos: alunosArr
                }
            }))

            Axios.post(
                "https://serviceturma.octaead.com.br/turma",
                this.state.fields
            )
                .then((responseData) => {
                    Axios.patch(
                        'https://serviceunidade.octaead.com.br/unidade/' + unidadeId,
                        {
                            $push: {
                                'turmas': responseData.data._id
                            }
                        }
                    )
                        .then(() => {
                            alunosArr.forEach((alunoID) => {
                                Axios.patch('https://serviceperfil.octaead.com.br/aluno/' + alunoID, {
                                    turma: responseData.data._id
                                })
                            })

                            SweetAlert({
                                title: "Tudo certo!",
                                text: "O cadastro foi salvo com sucesso. Você voltará para a listagem.",
                                icon: "success",
                                button: {
                                    text: "Legal!"
                                }
                            })
                                .then((response) => {
                                    if (response) {
                                        window.location.href = '/admin/turmas'
                                    }
                                })
                        })
                })
                .catch((err) => {
                    SweetAlert({
                        title: "Erro",
                        text: "Não foi possível finalizar o cadastro. Entre em contato com o mantenedor do sistema.",
                        icon: 'error'
                    })
                })
        }
    }

    handleChange = (event) => {
        let {name, type, value} = event.target

        this.setState(prevState => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: value
            }
        }))
    }

    async componentDidMount() {
        let coordenadoresData = await Axios.get('https://serviceperfil.octaead.com.br/coordenadores')
        let professoresData = await Axios.get('https://serviceperfil.octaead.com.br/professores')
        let cursosData = await Axios.get('https://servicecurso.octaead.com.br/cursos')
        let alunosData = await Axios.post('https://serviceperfil.octaead.com.br/alunos-filter', {
            turma: ""
        })

        let coordenadoresList = coordenadoresData.data.map((item, index) =>
            <option key={index} value={item._id} className="btn btn-light text-left w-100">{item.nome}</option>
        )
        let professoresList = professoresData.data.map((item, index) =>
            <option key={index} value={item._id} className="btn btn-light text-left w-100">{item.nome}</option>
        )
        let cursosList = cursosData.data.map((item, index) =>
            <option key={index} value={item._id}
                    className="btn btn-light text-left w-100">{item.codigo} - {item.nome}</option>
        )

        let alunosList = []
        alunosData.data.map((item, index) => {
            alunosList.push(
                <option
                    key={index}
                    value={item._id}
                    className="btn btn-light text-left w-100"
                >
                    {item.nome}
                </option>
            )
        })

        let unidades = await Axios.get('https://serviceunidade.octaead.com.br/unidades')
        let unidadesSelect = unidades.data.map((item, index) =>
            <option key={index} value={item._id}
                    className="btn btn-light text-left w-100">{item.codigo} - {item.nome}</option>
        )

        unidadesSelect.unshift(
            <option value="" key={-1}>Selecione</option>
        )

        this.setState({
            done: true,
            coordenadoresOptions: coordenadoresList,
            professoresOptions: professoresList,
            cursosOptions: cursosList,
            alunosOptions: alunosList,
            unidadesSelect: unidadesSelect
        })
    }

    searchCoordenadores = (event) => {
        let {name, value} = event.target
        jQuery('#coordenadoresContainer option').each((index, item) => {
            if (!item.innerHTML.toLowerCase().includes(value.toLowerCase())) {
                jQuery(item).addClass("d-none")
            } else {
                jQuery(item).removeClass("d-none")
            }
        })
    }

    searchProfessores = (event) => {
        let {name, value} = event.target
        jQuery('#professoresContainer option').each((index, item) => {
            if (!item.innerHTML.toLowerCase().includes(value.toLowerCase())) {
                jQuery(item).addClass("d-none")
            } else {
                jQuery(item).removeClass("d-none")
            }
        })
    }

    searchCursos = (event) => {
        let {name, value} = event.target
        jQuery('#cursosContainer option').each((index, item) => {
            if (!item.innerHTML.toLowerCase().includes(value.toLowerCase())) {
                jQuery(item).addClass("d-none")
            } else {
                jQuery(item).removeClass("d-none")
            }
        })
    }

    searchAlunos = (event) => {
        let {name, value} = event.target
        jQuery('#alunosContainer option').each((index, item) => {
            if (!item.innerHTML.toLowerCase().includes(value.toLowerCase())) {
                jQuery(item).addClass("d-none")
            } else {
                jQuery(item).removeClass("d-none")
            }
        })
    }


    render() {
        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <div id="turmas-cadastro" className="px-3 px-md-5 my-5">
                <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">Cadastro de nova
                    Turma</h6>
                <div className="card">
                    <div className="card-body p-4">
                        <div className="d-block">
                            <div className="row align-items-start justify-content-start">
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="codigo">
                                            <small id="codigolabel" className="form-text text-muted mb-2">
                                                Código da Turma
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="codigo"
                                                name="codigo"
                                                value={this.state.fields.codigo}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="nome">
                                            <small id="nomelabel" className="form-text text-muted mb-2">
                                                Nome da Turma
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="nome"
                                                name="nome"
                                                value={this.state.fields.nome}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="vagas">
                                            <small id="vagaslabel" className="form-text text-muted mb-2">
                                                Quantidade de vagas
                                            </small>
                                            <input
                                                type="number"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="vagas"
                                                name="vagas"
                                                min={0}
                                                max={999}
                                                step={1}
                                                value={this.state.fields.vagas}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="dataInicio">
                                            <small id="dataIniciolabel" className="form-text text-muted mb-2">
                                                Início
                                            </small>
                                            <input
                                                type="date"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="dataInicio"
                                                name="dataInicio"
                                                value={this.state.fields.dataInicio}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="dataTermino">
                                            <small id="dataTerminolabel" className="form-text text-muted mb-2">
                                                Término
                                            </small>
                                            <input
                                                type="date"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="dataTermino"
                                                name="dataTermino"
                                                value={this.state.fields.dataTermino}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-5">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="unidade">
                                            <small id="unidadelabel" className="form-text text-muted mb-2">
                                                Unidade
                                            </small>
                                            <select
                                                className="custom-select rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="unidade"
                                                name="unidade"
                                                onChange={this.handleChange}
                                            >
                                                {this.state.unidadesSelect}
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="independente">
                                            <small id="independentelabel" className="form-text text-muted mb-2">
                                                Alunos entrarão independentemente?
                                            </small>
                                            <select
                                                className="custom-select rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="independente"
                                                name="independente"
                                                onChange={this.handleChange}
                                            >
                                                <option value="false">Não</option>
                                                <option value="true">Sim</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="observacoes">
                                            <small id="observacoeslabel" className="form-text text-muted mb-2">
                                                Observações
                                            </small>
                                            <textarea
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="observacoes"
                                                name="observacoes"
                                                rows={5}
                                                value={this.state.fields.observacoes}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="coordenadores">
                                            <small id="coordenadoreslabel" className="form-text text-muted mb-2">
                                                Coordenadores
                                            </small>
                                            <div className="card">
                                                <div className="card-body p-3">
                                                    <div className="d-block text-right">
                                                        <div id="coordenadoresContainer" className={"rounded"}>
                                                            <select multiple
                                                                    className={"form-control custom-select p-0 d-flex flex-column"}
                                                                    name={"coordenadoresReturn"}>
                                                                {this.state.coordenadoresOptions}
                                                            </select>
                                                        </div>
                                                        <hr className="my-3"/>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Pesquisar coordenadores"
                                                            onKeyUp={this.searchCoordenadores}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="professores">
                                            <small id="professoreslabel" className="form-text text-muted mb-2">
                                                Professores
                                            </small>
                                            <div className="card">
                                                <div className="card-body p-3">
                                                    <div className="d-block text-right">
                                                        <div id="professoresContainer" className={"rounded"}>
                                                            <select multiple
                                                                    className={"form-control custom-select p-0 d-flex flex-column"}
                                                                    name={"professoresReturn"}>
                                                                {this.state.professoresOptions}
                                                            </select>
                                                        </div>
                                                        <hr className="my-3"/>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Pesquisar professores"
                                                            onKeyUp={this.searchProfessores}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="cursos">
                                            <small id="cursoslabel" className="form-text text-muted mb-2">
                                                Cursos
                                            </small>
                                            <div className="card">
                                                <div className="card-body p-3">
                                                    <div className="d-block text-right">
                                                        <div id="cursosContainer" className={"rounded"}>
                                                            <select multiple
                                                                    className={"form-control custom-select p-0 d-flex flex-column"}
                                                                    name={"cursosReturn"}>
                                                                {this.state.cursosOptions}
                                                            </select>
                                                        </div>
                                                        <hr className="my-3"/>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Pesquisar cursos"
                                                            onKeyUp={this.searchCursos}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="alunos">
                                            <small id="alunoslabel" className="form-text text-muted mb-2">
                                                Alunos disponíveis (sem turma)
                                            </small>
                                            <div className="card">
                                                <div className="card-body p-3">
                                                    <div className="d-block text-right">
                                                        <div id="alunosContainer" className={"rounded"}>
                                                            <select multiple
                                                                    className={"form-control custom-select p-0 d-flex flex-column"}
                                                                    name={"alunosReturn"}>
                                                                {this.state.alunosOptions}
                                                            </select>
                                                        </div>
                                                        <hr className="my-3"/>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Pesquisar alunos"
                                                            onKeyUp={this.searchAlunos}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="mb-4 mt-5"/>
                        <div className="d-block text-right">
                            <Link to={"/admin/turmas"}
                                  onClick={this.cancelarCadastro}
                                  className="btn border-radius py-2 text-center bg-secondary text-white mr-3"
                            >
                                Cancelar
                            </Link>
                            <Link to={""}
                                  onClick={this.salvarCadastro}
                                  className="btn border-radius py-2 text-center bg-primary text-white"
                            >
                                Salvar
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TurmasCadastroView;