// Módulos
import React, {Component} from "react"

class QuestaoArquivo extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="d-block card">
                <div className="card-body bg-light p-3">
                    <h6 className="text-center font-weight-bold my-2 text-center text-uppercase">
                        Um campo do tipo "enviar arquivo" será exibido para o aluno
                    </h6>
                </div>
            </div>
        )
    }
}

export default QuestaoArquivo