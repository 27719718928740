// CSS
import "./AlunoCursosView.css"

// Módulos
import React, {Component, useState, useEffect} from "react"
import {Link} from "react-router-dom"
import Axios from 'axios'

// Components
import ReactLoading from "react-loading"
import AtividadeCard from "../../components/AtividadeCard"
import AulaCard from "../../components/AulaCard"

class AlunoCursosView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            done: false,
            dadosCurso: {},
            Atividades: null,
            Calls: [],
            id: props.match.params.id
        }
    }

    async loadCursos(cursoId) {
        return await Axios.get('https://servicecurso.octaead.com.br/curso/' + cursoId)
    }

    async loadAtividades(cursoId, turmaId) {
        let Atividades = []
        let atividadesResponseData = await Axios.post(
            'https://serviceatividade.octaead.com.br/atividades-filter',
            {
                $and: [
                    {cursos: cursoId},
                    {turmas: turmaId}
                ]
            })

        Atividades.push(atividadesResponseData.data)

        return Atividades
    }

    async mountAtividades(Atividades, materia) {
        let returnArr = []
        let basePath = this.props.location.pathname

        await Promise.all(
            Atividades[0].map((atividade, j) => {
                returnArr.push(
                    <AtividadeCard
                        key={j}
                        path={basePath}
                        atividadeData={atividade}
                        materia={materia}
                    />
                )
            })
        )

        return returnArr
    }

    async mountLive(CursoId) {
        let returnArr = []

        let callInfo = await Axios.post('https://servicecall.octaead.com.br/calls-filter', {
            cursos: CursoId
        })

        callInfo.data.map((call, i) => {
            if (call.status === "waiting") {
                returnArr.push(
                    <AulaCard path={this.props.location.pathname} call={call} materia={this.state.dadosCurso.nome}/>
                )
            }
        })

        if (returnArr.length > 0) {
            return returnArr
        } else {
            return (
                <div className={"card p-3 w-100"}>
                    Este curso ainda não tem nenhuma aula ao vivo.
                </div>
            )
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({
                done: false
            })

            let Cursos = await this.loadCursos(this.props.match.params.id)
            let Atividades = await this.loadAtividades(this.props.match.params.id, localStorage.getItem('turmaId'))
            let MountAtividades = await this.mountAtividades(Atividades, Cursos.data.nome)

            this.setState({
                dadosCurso: Cursos.data,
                Atividades: MountAtividades,
            })

            let MountLives = await this.mountLive(Cursos.data._id)
            this.setState({
                Lives: MountLives,
                done: true
            })
        }
    }

    async componentDidMount() {
        let Cursos = await this.loadCursos(this.state.id)
        let Atividades = await this.loadAtividades(this.state.id, localStorage.getItem('turmaId'))
        let MountAtividades = await this.mountAtividades(Atividades, Cursos.data.nome)

        this.setState({
            dadosCurso: Cursos.data,
            Atividades: MountAtividades,
        })

        let MountLives = await this.mountLive(Cursos.data._id)
        this.setState({
            Lives: MountLives,
            done: true
        })
    }

    render() {
        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <div className={"mt-4"}>
                <div className="container-fluid mb-5 pb-md-4">
                    <div className="row" id={"last-activities"}>
                        {this.state.dadosCurso.observacoes ?
                            <div className="col-12 px-3 px-md-5">
                                <div className="alert alert-primary alert-dismissible fade show mt-2 mb-5" role="alert">
                                    <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">Comunicado
                                        ao aluno</h6>
                                    <p>{this.state.dadosCurso.observacoes}</p>
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                            : null
                        }

                        <div className="col-12 px-3 px-md-5 mb-5">
                            <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">
                                Minhas Aulas - {this.state.dadosCurso.nome}
                            </h6>
                            <div className="card">
                                <div className="card-body p-2">
                                    <section id="activity-list">
                                        <div className="container-fluid p-3">
                                            <div className="row px-0">
                                                <div className="col-12 col-md-8 mb-4 mb-md-0">
                                                    <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">
                                                        Aulas gravadas
                                                    </h6>
                                                    <div className={"card p-3 w-100"}>
                                                        Este recurso ainda não está disponível em sua conta.
                                                    </div>
                                                    {/* <Link to={""}
                                                          className="btn btn-light py-2 d-flex align-items-start justify-content-start text-left">
                                                        <div className="d-block">
                                                            <h2 className="title font-weight-bold">
                                                                Nome da aula, apresentada ao vivo e salva em banco de
                                                                dados para conferência futura
                                                            </h2>
                                                            <p className="teacher">Professor(a): Rafaela Rodrigues</p>
                                                        </div>
                                                        <div className="d-block text-right col-4 p-0">
                                                            <p className="start">
                                                                Aula transmitida em<br/>
                                                                15/05/2020
                                                            </p>
                                                            <div className="small d-block text-uppercase mt-2">
                                                                <i className="fa fa-angle-right mr-1"></i> Clique para
                                                                ver novamente
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <hr className="my-2"/> */}
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-4 px-2">
                                                    <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">
                                                        Aulas ao vivo
                                                    </h6>
                                                    {this.state.Lives}
                                                </div>

                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>

                        <div id="search-area" className="col-12 px-3 px-md-5 mb-5">
                            <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">Buscar
                                Atividade</h6>
                            <form className="container-fluid" action="" method="post">
                                <div className="row px-0">
                                    <div className="col-10 col-md-11 px-0">
                                        <input type="search" name="search"
                                               placeholder="Digite parte do nome da atividade"
                                               className="form-control search px-3 py-4"/>
                                    </div>
                                    <div className="col-2 col-md-1 p-0">
                                        <button type="submit" className="btn w-100 h-100 text-white bg-primary submit">
                                            <i className="fas fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="col-12 px-3 px-md-5 mb-5">
                            <div className="card">
                                <div className="card-body p-2">
                                    <section id="type-of-activities">
                                        <ul className="mx-0 px-0 border-bottom mb-5">
                                            <li className="font-weight-bold p-3 mr-3 border-radius active">
                                                <Link className={"text-secondary"} to={""}>Atividades para nota</Link>
                                            </li>
                                            <li className="font-weight-bold p-3 mr-3 border-radius">
                                                <Link className={"text-secondary"} to={""}>Novas atividades</Link>
                                            </li>
                                            <li className="font-weight-bold p-3 mr-3 border-radius">
                                                <Link className={"text-secondary"} to={""}>Atividades com Atenção</Link>
                                            </li>
                                            <li className="font-weight-bold p-3 mr-3 border-radius">
                                                <Link className={"text-secondary"} to={""}>Atividades encerradas</Link>
                                            </li>
                                            <li className="font-weight-bold p-3 mr-3 border-radius">
                                                <Link className={"text-secondary"} to={""}>Lições de casa</Link>
                                            </li>
                                            <li className="font-weight-bold p-3 mr-3 border-radius">
                                                <Link className={"text-secondary"} to={""}>Atividades
                                                    complementares</Link>
                                            </li>
                                        </ul>
                                    </section>

                                    <section id="activity-list">
                                        <div className="container-fluid px-2">
                                            <div className="row px-0">
                                                {this.state.Atividades}
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default AlunoCursosView