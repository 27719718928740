// CSS
import "./SidebarRight.css"

// Módulos
import React, {Component} from "react"
import {Link} from "react-router-dom"
import ReactLoading from "react-loading";

class SidebarRight extends Component {
    constructor(props) {
        super(props)

        this.state = {
            done: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.questaoAtual !== this.props.questaoAtual) {
            let Questoes = []
            this.props.questoes.map((questao, j) => {
                let answered = false

                questao.alternativas.map((alternativa, a) => {
                    if (alternativa.selecionada) {
                        answered = true
                    }
                })

                Questoes.push(
                    <div
                        id="prev-question"
                        onClick={() => this.props.onChange(j)}
                        className={
                            "py-2 px-3 bg-white border border-radius mb-2 d-flex align-items-center text-secondary cursor-pointer " +
                            (answered ? 'already-answered' : '') +
                            (this.props.questaoAtual == j ? ' current' : '')
                        }
                    >
                        <div className="mr-2"><i className="fas fa-comment-alt fa-lg mr-2"></i></div>
                        <div className="small d-block">
                            {questao.titulo}
                        </div>
                    </div>
                )
            })

            this.setState({
                Questoes: Questoes,
                done: true
            })
        }
    }

    componentDidMount() {
        let Questoes = []
        this.props.questoes.map((questao, j) => {
            let answered = false

            questao.alternativas.map((alternativa, a) => {
                if (alternativa.selecionada) {
                    answered = true
                }
            })

            Questoes.push(
                <div
                    id="prev-question"
                    onClick={() => this.props.onChange(j)}
                    className={
                        "py-2 px-3 bg-white border border-radius mb-2 d-flex align-items-center text-secondary cursor-pointer " +
                        (answered ? 'already-answered' : '') +
                        (this.props.questaoAtual == j ? ' current' : '')
                    }
                >
                    <div className="mr-2"><i className="fas fa-comment-alt fa-lg mr-2"></i></div>
                    <div className="small d-block">
                        {questao.titulo}
                    </div>
                </div>
            )
        })

        this.setState({
            Questoes: Questoes,
            done: true
        })
    }

    render() {
        let count = this.props.questoes.length <= 9 ? '0' + this.props.questoes.length : this.props.questoes.length
        count = this.props.questoes.length > 1 ? count + " Questões" : count + " Questão"

        return !this.state.done ? (
            <section id="sidebar-right" className="col-12 col-md-4">
                <div id="labels-activities" className="w-100 p-3 bg-white p-3 border border-radius mb-3">
                    <div className="d-flex align-items-center justify-content-center h-100">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                        </div>
                    </div>
                </div>
            </section>
        ) : (
            <section id="sidebar-right" className="col-12 col-md-4">
                <div id="labels-activities" className="w-100 p-3 bg-white p-3 border border-radius mb-3">
                    <h2 className="font-weight-bold text-uppercase mb-3">{count}</h2>
                    <p>Legenda</p>
                    <div className="w-100 mt-2 d-flex align-items-center flex-wrap labels">
                        <div className="mr-3 d-flex align-items-center">
                            <span className="label-answered bg-white mr-2" style={{
                                border: "1px dashed #343a40"
                            }}></span>
                            Atual
                        </div>
                        <div className="col-12 my-1"></div>
                        <div className="mr-3 d-flex align-items-center">
                            <span className="label-answered mr-2"></span>
                            Respondida
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="label-no-answered mr-2"></span>
                            Não respondida
                        </div>
                    </div>
                </div>

                {this.state.Questoes}
            </section>
        )
    }
}

export default SidebarRight