// CSS
import './index.css'

// Módulos
import React from 'react'
import ReactDOM from 'react-dom'

// Components
import reportWebVitals from './reportWebVitals'

// Views
import App from './views/App'

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
)

reportWebVitals()
