import React, {Component} from "react"
import Axios from "axios"
import jQuery from 'jquery'
import DataTable from 'react-data-table-component'
import SweetAlert from 'sweetalert'
import ReactLoading from "react-loading"
import {Link} from "react-router-dom";

class UnidadesView extends Component {
    constructor(props) {
        super(props);
        const Scope = this;

        this.state = {
            dadosUnidades: [],
            done: false,
            TableColumns: [
                {
                    name: "Código",
                    selector: "codigo",
                    sortable: true,
                    width: "15%"
                },
                {
                    name: "Nome da Unidade",
                    selector: "nome",
                    sortable: true,
                    width: "45%"
                },
                {
                    name: "Status",
                    selector: "status",
                    width: "10%",
                    cell: (row) => (
                        <div className={"d-inline-block"}>
                            {row.status ? 'Ativo' : 'Inativo'}
                        </div>
                    ),
                    sortable: true
                },
                {
                    name: "Ações",
                    width: "30%",
                    cell: (row) => (
                        <div className="d-flex align-items-center">
                            <Link to={"/admin/unidades/editar/" + row._id}
                                  className="btn btn-primary text-white btn-sm mr-1">Editar
                            </Link>
                            <button onClick={() => Scope.deletarUnidade(row._id)}
                                    className="btn btn-danger btn-sm mr-1">Excluir
                            </button>
                            {row.status == 1 ? (
                                <button onClick={() => Scope.desativarUnidade(row._id)}
                                        className="btn btn-secondary btn-sm">Desativar
                                </button>
                            ) : (
                                <button onClick={() => Scope.ativarUnidade(row._id)}
                                        className="btn btn-success text-white btn-sm">Ativar
                                </button>
                            )}
                        </div>
                    )
                }
            ]
        }
    }

    ativarUnidade = (UnidadeID) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza de que quer ativar esta unidade?',
            icon: 'info',
            buttons: ['Cancelar', 'Sim, quero ativá-la']
        })
            .then((response) => {
                if (response) {
                    Axios.patch(
                        'https://serviceunidade.octaead.com.br/unidade/' + UnidadeID,
                        {status: 1}
                    )
                        .then(() => {
                            SweetAlert(
                                'Pronto!',
                                'Unidade ativada.',
                                'success'
                            ).then(() => {
                                window.location.reload()
                            })
                        })
                }
            })
    }

    desativarUnidade = (UnidadeID) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza de que quer desativar esta unidade?',
            icon: 'info',
            buttons: ['Cancelar', 'Sim, quero desativá-la']
        })
            .then((response) => {
                if (response) {
                    Axios.patch(
                        'https://serviceunidade.octaead.com.br/unidade/' + UnidadeID,
                        {status: 0}
                    )
                        .then(() => {
                            SweetAlert(
                                'Pronto!',
                                'Unidade desativada.',
                                'success'
                            ).then(() => {
                                window.location.reload()
                            })
                        })
                }
            })
    }

    deletarUnidade = (UnidadeID) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza de que quer excluir esta unidade?',
            icon: 'warning',
            dangerMode: true,
            buttons: ['Cancelar', 'Sim, quero excluir']
        })
            .then((response) => {
                if (response) {
                    Axios.delete('https://serviceunidade.octaead.com.br/unidade/' + UnidadeID)
                        .then(() => {
                            SweetAlert(
                                'Pronto!',
                                'Unidade excluída.',
                                'success'
                            ).then(() => {
                                window.location.reload()
                            })
                        })
                }
            })
    }

    loadUnidades = async () => {
        this.setState({
            done: false
        })

        await Axios.get('https://serviceunidade.octaead.com.br/unidades').then((responseData) => {
            this.setState({
                dadosUnidades: responseData.data,
                done: true
            })
        })
    }

    async componentDidMount() {
        await this.loadUnidades()
    }

    render() {
        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <div id="unidades-listagem" className="px-3 px-md-5 my-5">
                <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">Unidades</h6>
                <div className="card">
                    <div className="card-body p-4">
                        <DataTable
                            columns={this.state.TableColumns}
                            data={this.state.dadosUnidades}
                            noDataComponent={"Nenhum registro encontrado"}
                            pagination
                            striped
                            defaultSortField="nome"
                        />
                        <hr className="mb-4 mt-5"/>
                        <div className="d-block text-right">
                            <Link to={"/admin/unidades/nova"}
                                  className="btn border-radius py-2 text-center bg-primary text-white"
                            >
                                Cadastrar nova unidade
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UnidadesView;