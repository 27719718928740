import React, {Component} from "react"
import Axios from "axios"
import jQuery from 'jquery'
import SweetAlert from 'sweetalert'
import ReactLoading from "react-loading"
import {Link} from "react-router-dom"
import MaskedInput from "react-text-mask"

class TurmasCadastroEditarView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            done: false,
            id: props.match.params.id,
            alunosToRemove: [],
            fields: {
                codigo: "",
                independente: "false",
                nome: "",
                vagas: 0,
                dataInicio: "",
                dataTermino: "",
                observacoes: "",
                cursos: [],
                coordenadores: [],
                professores: [],
                alunos: []
            }
        }
    }

    cancelarCadastro = (event) => {
        event.preventDefault()

        SweetAlert({
            title: "Atenção!",
            text: "Tem certeza de que deseja cancelar? As informações digitadas serão perdidas!",
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: "Mudei de idéia",
                confirm: "Sim, quero cancelar"
            }
        })
            .then((response) => {
                if (response) {
                    window.location.href = event.target.href
                }
            })
    }

    salvarCadastro = async (event) => {
        event.preventDefault()

        if (
            this.state.fields.codigo.length <= 0 ||
            this.state.fields.nome.length <= 0 ||
            this.state.fields.vagas <= 0
        ) {
            SweetAlert(
                "Erro",
                "Por favor, verifique se o codigo, nome e vagas estão preenchidos corretamente."
            )
        } else {
            SweetAlert({
                text: "Tudo certo! Agora vamos salvar o cadastro.",
                button: {
                    text: "Ok, vou aguardar"
                }
            })

            let coordenadoresArr = []
            let professoresArr = []
            let cursosArr = []
            let alunosArr = []
            let alunosToRemove = this.state.alunosToRemove
            let novosAlunos = 0

            jQuery('#coordenadoresContainer option').each((index, item) => {
                if (jQuery(item).is(':selected')) {
                    coordenadoresArr.push(jQuery(item).val())
                }
            })
            jQuery('#professoresContainer option').each((index, item) => {
                if (jQuery(item).is(':selected')) {
                    professoresArr.push(jQuery(item).val())
                }
            })
            jQuery('#cursosContainer option').each((index, item) => {
                if (jQuery(item).is(':selected')) {
                    cursosArr.push(jQuery(item).val())
                }
            })
            jQuery('#alunosContainer option').each((index, item) => {
                if (jQuery(item).is(':selected')) {
                    alunosArr.push(jQuery(item).val())
                    novosAlunos = novosAlunos + 1
                }
            })
            jQuery('#alunosTurmaContainer option').each((index, item) => {
                if (!jQuery(item).attr('disabled')) {
                    alunosArr.push(jQuery(item).val())
                }
            })

            alunosArr = alunosArr.filter(function (el) {
                return alunosToRemove.indexOf(el) < 0
            })

            let dataSave = {
                codigo: this.state.fields.codigo,
                nome: this.state.fields.nome,
                vagas: this.state.fields.vagas - novosAlunos + alunosToRemove.length,
                independente: this.state.fields.independente,
                dataInicio: new Date(this.state.fields.dataInicio),
                dataTermino: new Date(this.state.fields.dataTermino),
                observacoes: this.state.fields.observacoes,
                coordenadores: coordenadoresArr,
                professores: professoresArr,
                cursos: cursosArr,
                alunos: alunosArr
            }

            Axios.patch(
                "https://serviceturma.octaead.com.br/turma/" + this.state.id,
                dataSave
            )
                .then((responseData) => {
                    alunosArr.forEach((alunoID) => {
                        Axios.patch('https://serviceperfil.octaead.com.br/aluno/' + alunoID, {
                            turma: this.state.id
                        })
                    })

                    if (alunosToRemove.length > 0) {
                        alunosToRemove.forEach((alunoID) => {
                            Axios.patch('https://serviceperfil.octaead.com.br/aluno/' + alunoID, {
                                turma: ""
                            })
                        })
                    }

                    SweetAlert({
                        title: "Tudo certo!",
                        text: "O cadastro foi salvo com sucesso. Você voltará para a listagem.",
                        icon: "success",
                        button: {
                            text: "Legal!"
                        }
                    })
                        .then((response) => {
                            if (response) {
                                window.location.href = '/admin/turmas'
                            }
                        })
                })
                .catch((err) => {
                    SweetAlert({
                        title: "Erro",
                        text: "Não foi possível finalizar o cadastro. Entre em contato com o mantenedor do sistema.",
                        icon: 'error'
                    })
                })
        }
    }

    handleChange = (event) => {
        let {name, type, value} = event.target

        this.setState(prevState => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: value
            }
        }))
    }

    async componentDidMount() {
        let coordenadoresData = await Axios.get('https://serviceperfil.octaead.com.br/coordenadores')
        let professoresData = await Axios.get('https://serviceperfil.octaead.com.br/professores')
        let cursosData = await Axios.get('https://servicecurso.octaead.com.br/cursos')
        let turmaData = await Axios.get('https://serviceturma.octaead.com.br/turma/' + this.state.id)
        let alunosData = await Axios.post('https://serviceperfil.octaead.com.br/alunos-filter', {
            turma: ""
        })
        let unidadesData = await Axios.get('https://serviceunidade.octaead.com.br/unidades')

        let dataInicio = new Date(turmaData.data.dataInicio)
        dataInicio.setTime(dataInicio.getTime() + (3 * 60 * 60 * 1000))
        let dataInicioRender = dataInicio.getFullYear() + '-' + ((dataInicio.getMonth() + 1) <= 9 ? '0' + (dataInicio.getMonth() + 1) : (dataInicio.getMonth() + 1)) + '-' + (dataInicio.getDate() <= 9 ? '0' + dataInicio.getDate() : dataInicio.getDate())

        let dataTermino = new Date(turmaData.data.dataTermino)
        dataTermino.setTime(dataTermino.getTime() + (3 * 60 * 60 * 1000))
        let dataTerminoRender = dataTermino.getFullYear() + '-' + ((dataTermino.getMonth() + 1) <= 9 ? '0' + (dataTermino.getMonth() + 1) : (dataTermino.getMonth() + 1)) + '-' + (dataTermino.getDate() <= 9 ? '0' + dataTermino.getDate() : dataTermino.getDate())

        await this.setState({
            fields: {
                codigo: turmaData.data.codigo,
                nome: turmaData.data.nome,
                independente: turmaData.data.independente,
                vagas: turmaData.data.vagas,
                dataInicio: dataInicioRender,
                dataTermino: dataTerminoRender,
                observacoes: turmaData.data.observacoes,
                coordenadores: turmaData.data.coordenadores,
                professores: turmaData.data.professores,
                cursos: turmaData.data.cursos,
                alunos: turmaData.data.alunos
            }
        })

        let coordenadoresList = []
        coordenadoresData.data.map((item, index) => {
            let selected = this.state.fields.coordenadores.indexOf(item._id) >= 0
            coordenadoresList.push(
                <option
                    selected={selected}
                    key={index}
                    value={item._id}
                    className="btn btn-light text-left w-100"
                >
                    {item.nome}
                </option>
            )
        })

        let professoresList = []
        professoresData.data.map((item, index) => {
            let selected = this.state.fields.professores.indexOf(item._id) >= 0
            professoresList.push(
                <option
                    selected={selected}
                    key={index}
                    value={item._id}
                    className="btn btn-light text-left w-100"
                >
                    {item.nome}
                </option>
            )
        })

        let cursosList = []
        cursosData.data.map((item, index) => {
            let selected = this.state.fields.cursos.indexOf(item._id) >= 0
            cursosList.push(
                <option
                    selected={selected}
                    key={index}
                    value={item._id}
                    className="btn btn-light text-left w-100"
                >
                    {item.codigo} - {item.nome}
                </option>
            )
        })

        let alunosList = []
        alunosData.data.map((item, index) => {
            alunosList.push(
                <option
                    key={index}
                    value={item._id}
                    className="btn btn-light text-left w-100"
                >
                    {item.nome}
                </option>
            )
        })

        let unidadesList = []
        unidadesData.data.map((item, index) => {
            let selected = item.turmas.indexOf(this.state.id) >= 0
            unidadesList.push(
                <option
                    key={index}
                    selected={selected}
                    value={item._id}
                    className="btn btn-light text-left w-100"
                >
                    {item.codigo} - {item.nome}
                </option>
            )
        })

        let alunosTurmaList = []
        for (let a in turmaData.data.alunos) {
            let alunoInfo = await Axios.get('https://serviceperfil.octaead.com.br/aluno/' + turmaData.data.alunos[a])
            alunosTurmaList.push(
                <option
                    key={a}
                    value={alunoInfo.data._id}
                    className="btn btn-light text-left w-100 p-2"
                >
                    {alunoInfo.data.nome}
                </option>
            )
        }

        await this.setState({
            coordenadoresOptions: coordenadoresList,
            professoresOptions: professoresList,
            cursosOptions: cursosList,
            alunosTurmaOptions: alunosTurmaList,
            alunosOptions: alunosList,
            unidadesSelect: unidadesList,
            done: true
        })
    }

    searchCoordenadores = (event) => {
        let {name, value} = event.target
        jQuery('#coordenadoresContainer option').each((index, item) => {
            if (!item.innerHTML.toLowerCase().includes(value.toLowerCase())) {
                jQuery(item).addClass("d-none")
            } else {
                jQuery(item).removeClass("d-none")
            }
        })
    }

    searchProfessores = (event) => {
        let {name, value} = event.target
        jQuery('#professoresContainer option').each((index, item) => {
            if (!item.innerHTML.toLowerCase().includes(value.toLowerCase())) {
                jQuery(item).addClass("d-none")
            } else {
                jQuery(item).removeClass("d-none")
            }
        })
    }

    searchCursos = (event) => {
        let {name, value} = event.target
        jQuery('#cursosContainer option').each((index, item) => {
            if (!item.innerHTML.toLowerCase().includes(value.toLowerCase())) {
                jQuery(item).addClass("d-none")
            } else {
                jQuery(item).removeClass("d-none")
            }
        })
    }

    searchAlunos = (event) => {
        let {name, value} = event.target
        jQuery('#alunosContainer option').each((index, item) => {
            if (!item.innerHTML.toLowerCase().includes(value.toLowerCase())) {
                jQuery(item).addClass("d-none")
            } else {
                jQuery(item).removeClass("d-none")
            }
        })
    }

    searchAlunosTurma = (event) => {
        let {name, value} = event.target
        jQuery('#alunosTurmaContainer option').each((index, item) => {
            if (!item.innerHTML.toLowerCase().includes(value.toLowerCase())) {
                jQuery(item).addClass("d-none")
            } else {
                jQuery(item).removeClass("d-none")
            }
        })
    }

    removeSelectedAlunos = (event) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza de que quer remover estes alunos da turma?',
            icon: 'warning',
            dangerMode: true,
            buttons: ['Cancelar', 'Sim, quero removê-los']
        })
            .then(async (response) => {
                if (response) {
                    let alunosToRemove = [...this.state.alunosToRemove, ...jQuery('#alunosTurmaReturn').val()]
                    let alunosTurmaList = []
                    for (let a of this.state.alunosTurmaOptions) {
                        let disabled = alunosToRemove.indexOf(a.props.value) >= 0
                        alunosTurmaList.push(
                            <option
                                key={a.key}
                                disabled={disabled}
                                value={a.props.value}
                                className="btn btn-light text-left w-100 p-2"
                            >
                                {a.props.children}
                            </option>
                        )
                    }

                    jQuery('#alunosTurmaReturn').val("")

                    await this.setState({
                        alunosTurmaOptions: alunosTurmaList,
                        alunosToRemove: alunosToRemove
                    })
                }
            })
    }

    cancelSelectedAlunos = async () => {
        let alunosTurmaList = []
        for (let a of this.state.alunosTurmaOptions) {
            alunosTurmaList.push(
                <option
                    key={a.key}
                    value={a.props.value}
                    className="btn btn-light text-left w-100 p-2"
                >
                    {a.props.children}
                </option>
            )
        }

        jQuery('#alunosTurmaReturn').val("")

        await this.setState({
            alunosTurmaOptions: alunosTurmaList,
            alunosToRemove: []
        })
    }

    render() {
        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <div id="turmas-cadastro" className="px-3 px-md-5 my-5">
                <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">Cadastro de nova
                    Turma</h6>
                <div className="card">
                    <div className="card-body p-4">
                        <div className="d-block">
                            <div className="row align-items-start justify-content-start">
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="codigo">
                                            <small id="codigolabel" className="form-text text-muted mb-2">
                                                Código da Turma
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="codigo"
                                                name="codigo"
                                                value={this.state.fields.codigo}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="nome">
                                            <small id="nomelabel" className="form-text text-muted mb-2">
                                                Nome da Turma
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="nome"
                                                name="nome"
                                                value={this.state.fields.nome}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="vagas">
                                            <small id="vagaslabel" className="form-text text-muted mb-2">
                                                Quantidade de vagas
                                            </small>
                                            <input
                                                type="number"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="vagas"
                                                name="vagas"
                                                min={0}
                                                max={999}
                                                step={1}
                                                value={this.state.fields.vagas}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="dataInicio">
                                            <small id="dataIniciolabel" className="form-text text-muted mb-2">
                                                Início
                                            </small>
                                            <input
                                                type="date"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="dataInicio"
                                                name="dataInicio"
                                                value={this.state.fields.dataInicio}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="dataTermino">
                                            <small id="dataTerminolabel" className="form-text text-muted mb-2">
                                                Término
                                            </small>
                                            <input
                                                type="date"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="dataTermino"
                                                name="dataTermino"
                                                value={this.state.fields.dataTermino}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-5">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="unidade">
                                            <small id="unidadelabel" className="form-text text-muted mb-2">
                                                Unidade
                                            </small>
                                            <select
                                                className="custom-select rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0 disabled"
                                                disabled
                                                id="unidade"
                                                name="unidade"
                                                onChange={this.handleChange}
                                            >
                                                {this.state.unidadesSelect}
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="independente">
                                            <small id="independentelabel" className="form-text text-muted mb-2">
                                                Alunos entrarão independentemente?
                                            </small>
                                            <select
                                                className="custom-select rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="independente"
                                                name="independente"
                                                onChange={this.handleChange}
                                            >
                                                <option
                                                    selected={this.state.fields.independente === false}
                                                    value="false"
                                                >
                                                    Não
                                                </option>
                                                <option
                                                    selected={this.state.fields.independente === true}
                                                    value="true"
                                                >
                                                    Sim
                                                </option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="observacoes">
                                            <small id="observacoeslabel" className="form-text text-muted mb-2">
                                                Observações
                                            </small>
                                            <textarea
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="observacoes"
                                                name="observacoes"
                                                rows={5}
                                                value={this.state.fields.observacoes}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="coordenadores">
                                            <small id="coordenadoreslabel" className="form-text text-muted mb-2">
                                                Coordenadores
                                            </small>
                                            <div className="card">
                                                <div className="card-body p-3">
                                                    <div className="d-block text-right">
                                                        <div id="coordenadoresContainer" className={"rounded"}>
                                                            <select multiple
                                                                    className={"form-control custom-select p-0 d-flex flex-column"}
                                                                    name={"coordenadoresReturn"}>
                                                                {this.state.coordenadoresOptions}
                                                            </select>
                                                        </div>
                                                        <hr className="my-3"/>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Pesquisar coordenadores"
                                                            onKeyUp={this.searchCoordenadores}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="professores">
                                            <small id="professoreslabel" className="form-text text-muted mb-2">
                                                Professores
                                            </small>
                                            <div className="card">
                                                <div className="card-body p-3">
                                                    <div className="d-block text-right">
                                                        <div id="professoresContainer" className={"rounded"}>
                                                            <select multiple
                                                                    className={"form-control custom-select p-0 d-flex flex-column"}
                                                                    name={"professoresReturn"}>
                                                                {this.state.professoresOptions}
                                                            </select>
                                                        </div>
                                                        <hr className="my-3"/>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Pesquisar professores"
                                                            onKeyUp={this.searchProfessores}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="cursos">
                                            <small id="cursoslabel" className="form-text text-muted mb-2">
                                                Cursos
                                            </small>
                                            <div className="card">
                                                <div className="card-body p-3">
                                                    <div className="d-block text-right">
                                                        <div id="cursosContainer" className={"rounded"}>
                                                            <select multiple
                                                                    className={"form-control custom-select p-0 d-flex flex-column"}
                                                                    name={"cursosReturn"}>
                                                                {this.state.cursosOptions}
                                                            </select>
                                                        </div>
                                                        <hr className="my-3"/>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Pesquisar cursos"
                                                            onKeyUp={this.searchCursos}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="alunosTurma">
                                            <small id="alunosTurmalabel" className="form-text text-muted mb-2">
                                                Alunos nesta turma
                                            </small>
                                            <div className="card">
                                                <div className="card-body p-3">
                                                    <div className="d-block text-right">
                                                        <div id="alunosTurmaContainer" className={"rounded"}>
                                                            <select multiple
                                                                    className={"form-control custom-select p-0 d-flex flex-column"}
                                                                    name={"alunosTurmaReturn"}
                                                                    id={"alunosTurmaReturn"}
                                                            >
                                                                {this.state.alunosTurmaOptions}
                                                            </select>
                                                        </div>
                                                        <hr className="my-3"/>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Pesquisar alunos"
                                                            onKeyUp={this.searchAlunosTurma}
                                                        />
                                                        <hr className="my-3"/>
                                                        <button
                                                            className="btn btn-secondary mr-2"
                                                            placeholder="Pesquisar alunos"
                                                            onClick={this.cancelSelectedAlunos}
                                                        >
                                                            Cancelar marcação
                                                        </button>
                                                        <button
                                                            className="btn btn-danger"
                                                            placeholder="Pesquisar alunos"
                                                            onClick={this.removeSelectedAlunos}
                                                        >
                                                            Marcar para remover
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="alunos">
                                            <small id="alunoslabel" className="form-text text-muted mb-2">
                                                Alunos disponíveis (sem turma)
                                            </small>
                                            <div className="card">
                                                <div className="card-body p-3">
                                                    <div className="d-block text-right">
                                                        <div id="alunosContainer" className={"rounded"}>
                                                            <select multiple
                                                                    className={"form-control custom-select p-0 d-flex flex-column"}
                                                                    name={"alunosReturn"}>
                                                                {this.state.alunosOptions}
                                                            </select>
                                                        </div>
                                                        <hr className="my-3"/>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Pesquisar alunos"
                                                            onKeyUp={this.searchAlunos}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="mb-4 mt-5"/>
                        <div className="d-block text-right">
                            <Link to={"/admin/turmas"}
                                  onClick={this.cancelarCadastro}
                                  className="btn border-radius py-2 text-center bg-secondary text-white mr-3"
                            >
                                Cancelar
                            </Link>
                            <Link to={""}
                                  onClick={this.salvarCadastro}
                                  className="btn border-radius py-2 text-center bg-primary text-white"
                            >
                                Salvar
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TurmasCadastroEditarView;