// Módulos
import React, {Component} from "react"
import QuestaoAlternativa from "./ModuloQuestaoAlernativa"
import QuestaoDissertativa from "./ModuloQuestaoDissertativa"
import QuestaoArquivo from "./ModuloQuestaoArquivo"
import QuestaoVerdadeiroFalso from "./ModuloQuestaoVerdadeiroFalso"
import QuestaoAssociativa from "./ModuloQuestaoAssociativa"

class ModuloEscolhaTipoQuestao extends Component {
    constructor(props) {
        super(props)

        this.state = {
            questionType: null
        }
    }

    selectQuestionType = (event) => {
        let questionType = event.target.value
        let returnType = null

        switch (questionType) {
            case 'alternativa':
                returnType = <QuestaoAlternativa counter={this.props.counter}/>
                break
            case 'dissertativa':
                returnType = <QuestaoDissertativa/>
                break
            case 'associativa':
                returnType = <QuestaoAssociativa/>
                break
            case 'verdadeiro_falso':
                returnType = <QuestaoVerdadeiroFalso/>
                break
            case 'arquivo':
                returnType = <QuestaoArquivo/>
                break
        }

        this.setState({
            questionType: returnType
        })
    }

    render() {
        return (
            <div className="d-block card">
                <div className="card-body bg-light p-3">
                    <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">
                        Selecione o tipo de resposta que essa questão terá
                    </h6>
                    <select
                        name="questionType"
                        id="questionType"
                        onChange={this.selectQuestionType}
                        className={"custom-select"}
                    >
                        <option value="">Selecione</option>
                        <option value="alternativa">Alternativa</option>
                        <option value="dissertativa">Dissertativa</option>
                        <option value="associativa">Associativa</option>
                        <option value="verdadeiro_falso">Verdadeiro ou Falso</option>
                        <option value="arquivo">Arquivo</option>
                    </select>
                    <div className="d-block mt-5">
                        {this.state.questionType}
                    </div>
                </div>
            </div>
        )
    }
}

export default ModuloEscolhaTipoQuestao